// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Skeleton from "@mui/material/Skeleton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import AppConfig from "../../utils/app-config"
import CustomPagination from "../custom-pagination"
import EnhancedTableHead from "../enhanced-table-head"
import getComparator from "../../utils/sorting"
import Order from "./components/order"
import OrderProps from "../../services/order/model"

function OrderList({ orders, maxRows, isFetching }) {
  const { t } = useTranslation("orders")
  const theme = useTheme()

  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("personName")
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(maxRows)

  const headCells = [
    {
      id: "quotation.response.carrier.code",
      align: "center",
      disablePadding: false,
      label: t("orderHeaders.carrier.label"),
    },
    ...(AppConfig.features.showOrderTypeColumn ? [{ id: "type", disablePadding: false, label: t("orderHeaders.type.label") }] : [{ id: "type", hideSortIcon: true }]),
    {
      id: "number",
      disablePadding: false,
      label: t("orderHeaders.orderNumber.label"),
    },
    {
      id: "userInfo.companyName",
      disablePadding: false,
      label: t("orderHeaders.clientName.label"),
    },
    {
      id: "totalPackages",
      disablePadding: false,
      label: t("orderHeaders.parts.label"),
    },
    {
      id: "totalWeight",
      disablePadding: false,
      label: t("orderHeaders.totalWeight.label"),
    },
    {
      id: "quotation.response.rate.totals.discounted.total.amount",
      disablePadding: false,
      label: t("orderHeaders.totalPrice.label"),
    },
    {
      id: "quotation.response.rate.totals.account.total.amount",
      disablePadding: false,
      label: t("orderHeaders.totalCost.label"),
    },
    {
      id: "createdDate",
      disablePadding: false,
      label: t("orderHeaders.latestUpdate.label"),
    },
    {
      id: "orderStatus",
      disablePadding: false,
      label: t("orderHeaders.orderStatus.label"),
    },
    {
      id: "transitStatus",
      disablePadding: false,
      label: t("orderHeaders.carrierTracking.label"),
    },
    { id: "menu", hideSortIcon: true },
  ]

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  return (
    <Box sx={{ width: "auto" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table size="small" sx={{ backgroundColor: theme.palette.backgroundNeutralUnderground1.main }}>
            <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {!orders || isFetching ? (
                <>
                  {Array(3)
                    .fill()
                    .map((_, index) => {
                      return (
                        <TableRow key={"".concat(index)}>
                          <TableCell colSpan={13}>
                            <Skeleton variant="rectangular" height={50} width="100%" />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </>
              ) : (
                orders
                  .slice()
                  .sort(getComparator(order, orderBy))
                  .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                  .map((currentOrder) => {
                    return <Order key={currentOrder.id} order={currentOrder} />
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {orders && <CustomPagination count={Math.ceil(orders.length / rowsPerPage)} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} showFirstButton showLastButton />}
      </Paper>
    </Box>
  )
}

OrderList.propTypes = {
  orders: PropTypes.arrayOf(OrderProps),
  maxRows: PropTypes.number,
  isFetching: PropTypes.bool,
}

OrderList.defaultProps = {
  orders: undefined,
  maxRows: Infinity,
  isFetching: false,
}

export default OrderList
