// REACT
import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import FormGroup from "@mui/material/FormGroup"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Formik
import { useFormik } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import AutoCompletePaper from "../../../../../../../auto-complete-paper"
import { useEditClientDetailsMutation } from "../../../../../../slice"

function ClientPricingGroupDrawer({ client, pricingGroups, selectedPricingGroup, onSubmitEvent, setSubmitEvent, setShowClientPricingGroupDrawer }) {
  const { t } = useTranslation("clients")
  const customTheme = useTheme()

  const [setClientDetails] = useEditClientDetailsMutation()

  const defaultPricingGroup = {
    code: null,
    isDefault: null,
    name: pricingGroups && `${t("clientDetails.clientDetailsInformations.clientPricingGroup.default.label")}${" ("}${pricingGroups.find((pricingGroup) => pricingGroup?.isDefault === true).name}${")"}`,
  }

  const pricingGroupsWithDefault = [defaultPricingGroup, ...pricingGroups]

  const formik = useFormik({
    initialValues: {
      pricingGroup: selectedPricingGroup || pricingGroupsWithDefault?.find((pricingGroup) => pricingGroup.name === defaultPricingGroup.name),
    },
    validateOnChange: false,
    enableReinitialize: true,
    validate: () => {},
    onSubmit: async (formValues) => {
      await setClientDetails({ clientId: client.emailAddress, payload: { pricingGroupCode: formValues.pricingGroup.code } })
      setShowClientPricingGroupDrawer(false)
    },
  })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
      setSubmitEvent(undefined)
    }
  }, [onSubmitEvent])

  const handleGroupChange = (_, value) => {
    formik.setFieldValue("pricingGroup", value)
  }

  return (
    <Box component="div">
      <Typography variant="bodyMedium600" gutterBottom sx={(theme) => ({ pb: theme.spacing(1), fontWeight: theme.typography.fontWeightBold })}>
        {t("clientDetails.clientDetailsInformations.clientPricingGroupDrawer.description.label")}
      </Typography>
      <Box component="div" pt={customTheme.spacing(2)}>
        <FormGroup>
          <Autocomplete
            id="clientPricingGroup"
            disablePortal
            value={formik.values.pricingGroup || null}
            onChange={handleGroupChange}
            onBlur={formik.handleBlur}
            getOptionLabel={(option) => option?.name || ""}
            isOptionEqualToValue={(option, value) => {
              return option.name === value.name
            }}
            options={pricingGroupsWithDefault || []}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.code}>
                  {option.name}
                </li>
              )
            }}
            PaperComponent={AutoCompletePaper}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("clientDetails.clientDetailsInformations.clientPricingGroupDrawer.field.label")}
                variant="outlined"
                required
                error={formik.touched.pricingGroup && formik.errors.pricingGroup !== undefined}
                helperText={(formik.touched.pricingGroup && formik.errors.pricingGroup) || " "}
              />
            )}
            noOptionsText={t("clientDetails.clientDetailsInformations.clientPricingGroupDrawer.noOptionsText.label")}
            disableClearable
            size="small"
            fullWidth
          />
        </FormGroup>
      </Box>
    </Box>
  )
}

ClientPricingGroupDrawer.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.string,
    clientAddress: PropTypes.shape({
      personName: PropTypes.string,
      companyName: PropTypes.string,
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      city: PropTypes.string,
      provinceCode: PropTypes.string,
      countryCode: PropTypes.string,
      postalCode: PropTypes.string,
    }),
    emailAddress: PropTypes.string,
    billing: PropTypes.shape({
      type: PropTypes.string,
    }),
    pricingGroupCode: PropTypes.string,
  }).isRequired,
  pricingGroups: PropTypes.arrayOf(
    PropTypes.shape({
      pricingGroup: PropTypes.shape({
        code: PropTypes.string,
        isDefault: PropTypes.bool,
        name: PropTypes.string,
        usedBy: PropTypes.number,
      }),
    })
  ),
  selectedPricingGroup: PropTypes.shape({
    code: PropTypes.string,
    isDefault: PropTypes.bool,
    name: PropTypes.string,
    usedBy: PropTypes.number,
  }),
  onSubmitEvent: PropTypes.instanceOf(Object),
  setSubmitEvent: PropTypes.func,
  setShowClientPricingGroupDrawer: PropTypes.func.isRequired,
}

ClientPricingGroupDrawer.defaultProps = {
  onSubmitEvent: undefined,
  setSubmitEvent: undefined,
  pricingGroups: [],
  selectedPricingGroup: undefined,
}

export default ClientPricingGroupDrawer
