// REACT
import React from "react"

// MUI
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

// Theme
import { pxToThemeSpacing } from "../../../../../../../../soe-theme/src/utils"

// Others
import Currency from "../../../../../../../currency"

import { AuditItemSectionProps } from "../../../../../../../../models/audit"

function AuditItemClientCard({ rate, user }) {
  return (
    <Card variant="outlined" square sx={{ height: 1 }}>
      <Grid container alignItems="center">
        <Grid item xs={8}>
          <Stack m={pxToThemeSpacing(20)}>
            {user.clientAddress?.companyName && (
              <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                {user.clientAddress?.companyName}
              </Typography>
            )}
            {user.clientAddress?.personName && (
              <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                {user.clientAddress.personName}
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
          {rate.amount !== undefined && (
            <Typography variant="bodySmall400" mr={pxToThemeSpacing(20)} sx={(theme) => ({ fontSize: theme.utils.pxToRem(14), fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              <Currency amount={rate.amount} currency={rate.currency} currencyPosition="left" showSymbol />
            </Typography>
          )}
        </Grid>
      </Grid>
    </Card>
  )
}

AuditItemClientCard.propTypes = {
  rate: AuditItemSectionProps.isRequired,
  user: AuditItemSectionProps.isRequired,
}

export default AuditItemClientCard
