import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Typography from "@mui/material/Typography"

import { useTranslation } from "react-i18next"

import ClientList from "../client-list"

import { pxToThemeSpacing } from "../../soe-theme/src/utils"
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../soe-theme/src/components"
import { useAddAuditItemsOrderOrAddlChargeMutation } from "../audits/slice"
import { AuditItemTypeEnum } from "../audits/constant"

function ClientSelection({ auditId, auditItemId, auditItemType }) {
  const { t } = useTranslation("clientSelection")

  const [selectedClient, setSelectedClient] = useState()
  const [showConfirmationDrawer, setShowConfirmationDrawer] = useState(false)

  const [addAuditItemsOrderOrAddlCharge, { isLoading }] = useAddAuditItemsOrderOrAddlChargeMutation()

  const handleClientClick = (client) => {
    setSelectedClient(client)
    setShowConfirmationDrawer(true)
  }

  const handleConfirmationDrawerClose = () => {
    setShowConfirmationDrawer(false)
  }

  const handleConfirmationDrawerConfirm = () => {
    addAuditItemsOrderOrAddlCharge({ auditId, auditItemId, clientId: selectedClient.emailAddress }).then(() => {
      setShowConfirmationDrawer(false)
    })
  }

  return (
    <Box p={pxToThemeSpacing(10)}>
      <Box m={pxToThemeSpacing(10)}>
        <Typography variant="bodyMedium600" gutterBottom sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
          {t("title")}
        </Typography>
      </Box>
      <Divider />
      <Box m={pxToThemeSpacing(14)}>
        <ClientList externalHandleClientClick={handleClientClick} setSelectedClient={setSelectedClient} showMoreMenu={false} nbRowsPerPage={10} />
      </Box>
      {showConfirmationDrawer && (
        <SoeDrawer showDrawer={showConfirmationDrawer} setShowDrawer={setShowConfirmationDrawer} anchor="right" keepMounted={false}>
          <SoeDrawerContainer>
            <SoeDrawerHeader
              title={auditItemType === AuditItemTypeEnum.ORDER ? t("confirmationDialog.orderTitle") : t("confirmationDialog.additionalChargeTitle")}
              setShowDrawer={() => {
                setShowConfirmationDrawer(false)
              }}
            />
            <SoeDrawerContent>
              <Typography variant="bodyMedium600" component="span" sx={{ textTransform: "none" }}>
                {auditItemType === AuditItemTypeEnum.ORDER ? t("confirmationDialog.orderContent.label") : t("confirmationDialog.additionalChargeContent.label")}
              </Typography>
            </SoeDrawerContent>
            <SoeDrawerActions
              buttons={[
                {
                  action: handleConfirmationDrawerClose,
                  label: t("confirmationDialog.actions.cancel.label"),
                  variant: "outlined",
                  disabled: isLoading,
                },
                {
                  action: handleConfirmationDrawerConfirm,
                  label: t("confirmationDialog.actions.confirm.label"),
                  variant: "contained",
                  loading: isLoading,
                },
              ]}
            />
          </SoeDrawerContainer>
        </SoeDrawer>
      )}
    </Box>
  )
}

ClientSelection.propTypes = {
  auditId: PropTypes.string.isRequired,
  auditItemId: PropTypes.string.isRequired,
  auditItemType: PropTypes.string.isRequired,
}

export default ClientSelection
