// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import AddressSummary from "../../../../../address-summary"
import CarrierServiceLogo from "../../../../../carrier-service-logo"
import Currency from "../../../../../currency"
import DateTimeService from "../../../../../../services/date-time"
import TranslationService from "../../../../../../services/translation"

function AuditedOrder({ auditedOrder }) {
  const { i18n } = useTranslation("invoices")
  const customTheme = useTheme()

  return (
    <TableRow hover component="tr" sx={{ backgroundColor: customTheme.palette.contentNeutralPrimaryReverse.main }}>
      <TableCell component="td" scope="row" align="center">
        <CarrierServiceLogo logo={auditedOrder.order?.quotation?.response?.carrier?.logo} align="center" />
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
          {TranslationService.getLabelFromLocale(i18n.language, auditedOrder.service?.client?.actual?.fullName)}
        </Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        <Box component="div">
          <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
            {auditedOrder.order?.number}
          </Typography>
        </Box>
        <Box component="div">
          <Typography variant="bodySmall400" gutterBottom>
            {auditedOrder.tracking?.carrierInvoice?.actual?.masterId}
          </Typography>
        </Box>
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography variant="bodySmall400" gutterBottom>
          {DateTimeService.toLocaleDateString(auditedOrder.order?.createdDate)}
        </Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        <Box component="div">{auditedOrder.shipper?.client?.actual && <AddressSummary addressSummary={auditedOrder.shipper?.client?.actual} />}</Box>
      </TableCell>
      <TableCell component="td" scope="row">
        <Box component="div">{auditedOrder.recipient?.client?.actual && <AddressSummary addressSummary={auditedOrder.recipient?.client?.actual} />}</Box>
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, fontSize: theme.utils.pxToRem(20) })}>
          <Currency amount={auditedOrder.rate?.totals?.total?.carrierInvoice?.actual?.amount} currency={auditedOrder.rate?.totals?.total?.carrierInvoice?.actual?.currency} currencyPosition="right" showSymbol={false} />
        </Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, fontSize: theme.utils.pxToRem(20) })}>
          <Currency amount={auditedOrder.rate?.totals?.total?.client?.actual?.amount} currency={auditedOrder.rate?.totals?.total?.client?.actual?.currency} currencyPosition="right" showSymbol={false} />
        </Typography>
      </TableCell>
    </TableRow>
  )
}

AuditedOrder.propTypes = {
  auditedOrder: PropTypes.instanceOf(Object).isRequired,
}

export default AuditedOrder
