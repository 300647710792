// REACT
import React, { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import DateRangeFilter from "../date-range-filter"
import OrderList from "../order-list"
import SearchInput from "../search-input"

import { useGetOrdersQuery } from "../../services/order/slice"

function Orders() {
  const { t } = useTranslation("orders")
  const customTheme = useTheme()

  const [filteredOrders, setFilteredOrders] = useState()
  const [dataRangeFilterValues, setDataRangeFilterValues] = useState()

  const { data: orders, isFetching } = useGetOrdersQuery({ from: dataRangeFilterValues?.from, to: dataRangeFilterValues?.to }, { skip: !dataRangeFilterValues })

  useEffect(() => {
    setFilteredOrders(orders)
  }, [orders])

  return (
    <>
      <Card variant="elevation" square sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.strokeDefault.main}` })}>
        <CardHeader title={t("title")} />
      </Card>
      <Card variant="elevation" square sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.strokeDefault.main}` })}>
        <Box my={customTheme.utils.pxToThemeSpacing(20)} mx={customTheme.spacing(2)} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box component="div" width={1}>
            <SearchInput data={orders || []} setFilteredData={setFilteredOrders} width={0.75} excludeFields={["pk", "sk", "id", "entityType", "createdDate", "transitStatus", "user"]} />
          </Box>
          <Box component="div">
            <DateRangeFilter setDataRangeFilterValues={setDataRangeFilterValues} />
          </Box>
        </Box>
      </Card>
      <Card variant="elevation" square>
        <OrderList orders={filteredOrders || []} maxRows={15} isFetching={isFetching} />
      </Card>
    </>
  )
}

export default Orders
