import React, { useContext } from "react"
import PropTypes from "prop-types"

import { MenuItem } from "@mui/material"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { createAction } from "@reduxjs/toolkit"
import UserContext from "../../../../services/user/context"

import { signOut } from "../../slice"

function SignOut({ setIsMenuOpen }) {
  const { t } = useTranslation("authenticator")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { setCurrentUser } = useContext(UserContext)

  return (
    <MenuItem
      className="menuItem"
      onClick={() => {
        setCurrentUser(undefined)
        setIsMenuOpen(false)
        dispatch(signOut()).then(() => {
          const resetStore = createAction("RESET_STORE")
          dispatch(resetStore())
        })
        navigate("/", { replace: true })
      }}
    >
      {t("signOut.fields.signOutButton.label")}
    </MenuItem>
  )
}

SignOut.propTypes = {
  setIsMenuOpen: PropTypes.func,
}

SignOut.defaultProps = {
  setIsMenuOpen: () => {},
}

export default SignOut
