import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import PropTypes from "prop-types"
import React from "react"
import SearchIcon from "@mui/icons-material/Search"
import TextField from "@mui/material/TextField"
import deburr from "lodash/deburr"

import { useTranslation } from "react-i18next"

function SearchInput({ data, setFilteredData, width, excludeFields }) {
  const { t } = useTranslation("searchInput")

  const [searchText, setSearchText] = React.useState("")

  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
  }

  const requestSearch = (searchValue) => {
    setSearchText(searchValue)
    const searchRegex = new RegExp(deburr(escapeRegExp(searchValue)), "i")
    const filteredRows = data?.filter((entry) => {
      return Object.keys(entry).some((field) => {
        if (!entry[field] || excludeFields.includes(field) || typeof entry[field] === "object") return false
        return searchRegex.test(deburr(entry[field].toString().toLowerCase()))
      })
    })
    setFilteredData(filteredRows)
  }

  return (
    <TextField
      variant="outlined"
      value={searchText}
      onChange={(event) => requestSearch(event.target.value)}
      placeholder={t("fields.search.placeHolder.label")}
      size="small"
      sx={{ width }}
      InputProps={{
        startAdornment: <SearchIcon fontSize="small" />,
        endAdornment: (
          <IconButton
            size="small"
            style={{ visibility: searchText ? "visible" : "hidden" }}
            onClick={() => {
              requestSearch("")
              setFilteredData(data)
            }}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
      }}
    />
  )
}

SearchInput.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  setFilteredData: PropTypes.func.isRequired,
  width: PropTypes.number || PropTypes.string,
  excludeFields: PropTypes.arrayOf(PropTypes.string),
}

SearchInput.defaultProps = {
  data: undefined,
  width: 0.3,
  excludeFields: ["id", "pk", "sk"],
}

export default SearchInput
