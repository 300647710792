import React, { useState } from "react"
import PropTypes from "prop-types"

import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import AppConfig from "../../../../../../utils/app-config"
import Currency from "../../../../../currency"
import EmailInvoiceDrawer from "../../../email-invoice-drawer"
import DateTimeService from "../../../../../../services/date-time"
import MoreMenu from "../../../../../more-menu"
import CustomChip from "../../../../../audits/components/audit/style"

import BILLING_TYPE_ENUM from "../../../../../../utils/billing"

function PaidInvoice({ invoice }) {
  const { t } = useTranslation("invoices")
  const customTheme = useTheme()

  const [showEmailInvoiceDrawer, setShowEmailInvoiceDrawer] = useState(false)

  const handlePrintClick = () => {
    window.open(`${AppConfig.aws.restApi.apiUrl}/invoices/print/${invoice.id}`, "_blank")
  }

  const handleEmailInvoiceClick = () => {
    setShowEmailInvoiceDrawer(true)
  }

  const sideMenuOptionList = [
    {
      label: t("paidInvoiceList.optionsList.print"),
      handleClick: handlePrintClick,
    },
    {
      label: t("paidInvoiceList.optionsList.emailInvoice"),
      handleClick: handleEmailInvoiceClick,
    },
  ]

  return (
    <>
      <TableRow hover component="tr" sx={{ backgroundColor: customTheme.palette.contentNeutralPrimaryReverse.main }}>
        <TableCell component="td" scope="row">
          {invoice.invoiceNumber}
        </TableCell>
        <TableCell component="td" scope="row">
          <Box component="div">{DateTimeService.toLocaleDateString(invoice.createdDate)}</Box>
        </TableCell>
        <TableCell component="td" scope="row">
          <Box component="div">{DateTimeService.toLocaleDateString(invoice.dueDate)}</Box>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography component="div">
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
              <Typography>
                <Typography noWrap sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
                  {invoice.user?.clientAddress?.companyName ? invoice.user.clientAddress.companyName : invoice.user.clientAddress.personName}
                </Typography>
                {invoice.user?.clientAddress?.companyName ? ` (${invoice.user.clientAddress.personName})` : ""}
              </Typography>
            </Box>
            <Typography noWrap variant="bodySmall400">
              {invoice.user?.emailAddress}
            </Typography>
          </Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, fontSize: theme.utils.pxToRem(20) })}>
            <Currency amount={invoice.summary?.invoice?.total} currency={invoice.currency} currencyPosition="right" showSymbol={false} />
          </Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, fontSize: theme.utils.pxToRem(20) })}>
            {invoice.summary?.amountPaid !== 0 && <Currency amount={invoice.summary?.amountPaid} currency={invoice.currency} currencyPosition="right" showSymbol={false} />}
          </Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          {invoice.user.billing?.type === BILLING_TYPE_ENUM.ACCOUNT && (
            <Chip label={t(`invoicesStatus.readyToInvoice.userGroup.${invoice.user?.billing?.type}`)} size="small" color="primary" sx={(theme) => ({ color: theme.palette.contentNeutralPrimaryReverse.main })} />
          )}
          {invoice.user.billing?.type === BILLING_TYPE_ENUM.CREDIT_CARD && (
            <Chip label={t(`invoicesStatus.readyToInvoice.userGroup.${invoice.user?.billing?.type}`)} size="small" color="secondary" sx={(theme) => ({ color: theme.palette.contentNeutralPrimaryReverse.main })} />
          )}
        </TableCell>
        <TableCell component="td" scope="row">
          {invoice?.sentByEmail && <CustomChip label={t("unpaidInvoiceList.emailSent")} color="success" size="small" sx={{ color: "white" }} />}
        </TableCell>
        <TableCell component="td" scope="row" align="right">
          <MoreMenu optionsList={sideMenuOptionList} />
        </TableCell>
      </TableRow>
      {showEmailInvoiceDrawer && <EmailInvoiceDrawer invoice={invoice} showEmailInvoiceDrawer={showEmailInvoiceDrawer} setShowEmailInvoiceDrawer={setShowEmailInvoiceDrawer} />}
    </>
  )
}

PaidInvoice.propTypes = {
  invoice: PropTypes.instanceOf(Object).isRequired,
}

export default PaidInvoice
