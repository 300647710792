/* eslint-disable no-undef */
import Box from "@mui/material/Box"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import React, { useEffect } from "react"
import Skeleton from "@mui/material/Skeleton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
import Typography from "@mui/material/Typography"

import { useNavigate, useParams } from "react-router-dom"
import { useTheme } from "@emotion/react"
import { useTranslation } from "react-i18next"

import AuditDetailsHeader from "./components/audit-details-header"
import AuditItem from "./components/audit-item"
import AuditItemsHeader from "./components/audit-items-header"

import { pxToThemeSpacing } from "../../../../soe-theme/src/utils"
import { ROUTES } from "../../../../constant"
import { useGetAuditDetailsQuery, useGetAuditDetailsItemsQuery } from "../../slice"

function AuditDetails() {
  const { t } = useTranslation("audits")
  const navigate = useNavigate()
  const theme = useTheme()

  const { auditId } = useParams()

  const { data: audit } = useGetAuditDetailsQuery(auditId, { skip: !auditId })
  const { data: auditItems, isFetching } = useGetAuditDetailsItemsQuery(auditId, { skip: !auditId })

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" onClick={() => navigate(ROUTES.AUDITS)}>
      {t("auditDetails.breadcrumbs.audits")}
    </Link>,
    <Typography key="3" color="text.primary">
      {t("auditDetails.breadcrumbs.details")}
    </Typography>,
  ]

  const handleSavePageYOffset = () => {
    localStorage.setItem("auditItemPageYOffset", window.pageYOffset)
  }

  useEffect(() => {
    if (auditItems) {
      const auditItemPageYOffset = localStorage.getItem("auditItemPageYOffset")
      if (auditItemPageYOffset) {
        window.scrollTo(0, auditItemPageYOffset)
        localStorage.removeItem("auditItemPageYOffset")
      }
    }
  }, [auditItems])

  return (
    <Box flexDirection="column" sx={{ backgroundColor: theme.palette.backgroundNeutralUnderground1 }}>
      <Card variant="standard" square sx={{ height: "auto" }}>
        <CardHeader title={t("auditDetails.title")} style={{ paddingLeft: theme.spacing(4), paddingBottom: theme.spacing(2) }} />
      </Card>
      <Card variant="standard" square sx={{ height: "auto" }}>
        <Box mb={pxToThemeSpacing(10)} ml={pxToThemeSpacing(34)}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
      </Card>
      <Box flexDirection="column">
        <Grid container>
          <Grid item xs={12}>
            {audit && <AuditDetailsHeader audit={audit} />}
          </Grid>
        </Grid>
        {!auditItems || isFetching ? (
          <Skeleton variant="rectangular" width="100%" height={100} />
        ) : (
          <TableContainer>
            <Table size="small">
              <TableBody>
                {audit?.carrier && <AuditItemsHeader carrierLogo={audit.carrier.logo} />}
                {auditItems &&
                  auditItems.map((auditItem, index) => {
                    return <AuditItem key={"".concat(index)} auditItem={auditItem} auditId={auditId} handleSavePageYOffset={handleSavePageYOffset} />
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Box>
  )
}

export default AuditDetails
