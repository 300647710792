// REACT
import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"

// Formik
import { useFormik } from "formik"

// Theme
import FullAddressForm from "../../../../../../../full-address-form"

import { getAddressFormat, isRequiredField } from "../../../../../../../../services/address-form"
import { useGetAddressFormatsQuery, useGetCountriesQuery } from "../../../../../../../../services/address-form/slice"
import { useEditClientDetailsMutation } from "../../../../../../slice"

function ClientBillingContactDrawer({ client, onSubmitEvent, setShowClientBillingContactDrawer }) {
  const { data: countries } = useGetCountriesQuery()
  const { data: addressFormats } = useGetAddressFormatsQuery()
  const [setClientDetails] = useEditClientDetailsMutation()

  const { t } = useTranslation("userProfile")

  const formik = useFormik({
    initialValues: {
      companyName: client?.billing?.address?.companyName || "",
      personName: client?.billing?.address?.personName || "",
      addressLine1: client?.billing?.address?.addressLine1 || "",
      addressLine2: client?.billing?.address?.addressLine2 || "",
      city: client?.billing?.address?.city || "",
      provinceName: client?.billing?.address?.provinceName || "",
      provinceCode: client?.billing?.address?.provinceCode || "",
      countryCode: client?.billing?.address?.countryCode || "CA",
      postalCode: client?.billing?.address?.postalCode || "",
      phoneNumber: client?.billing?.address?.phoneNumber || "",
      emailAddress: client?.billing?.address?.emailAddress || "",
    },
    validate: (formValues) => {
      const errors = {}

      if (!formValues.personName) {
        errors.personName = t("fields.personName.error.required")
      }

      const addressFormat = getAddressFormat({ addressFormats, countries, countryCode: formValues.countryCode })

      addressFormat?.forEach((fieldFormat) => {
        if (isRequiredField({ addressFormat, fieldName: fieldFormat.name }) && !formValues[fieldFormat.name]) {
          errors[fieldFormat.name] = t(`fields.${fieldFormat.name}.error.required`)
        }
      })

      if (!formValues.personName) {
        errors.personName = t("fields.personName.error.required")
      }

      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      await setClientDetails({ clientId: client.emailAddress, payload: { billing: { address: formValues } } })
      formik.resetForm()
      setShowClientBillingContactDrawer(false)
    },
  })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  return (
    <Box component="div">
      <FullAddressForm formik={formik} displaySearchAddressBar displaySaveAddressCheckbox={false} displaySaveDefaultAddressCheckbox={false} displayResidentialToggle={false} displayEmailAddressField />
    </Box>
  )
}

ClientBillingContactDrawer.defaultProps = {
  onSubmitEvent: undefined,
  client: undefined,
}

ClientBillingContactDrawer.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.string,
    emailAddress: PropTypes.string,
    billing: PropTypes.shape({
      address: PropTypes.shape({
        personName: PropTypes.string,
        companyName: PropTypes.string,
        addressLine1: PropTypes.string,
        addressLine2: PropTypes.string,
        city: PropTypes.string,
        provinceName: PropTypes.string,
        provinceCode: PropTypes.string,
        countryCode: PropTypes.string,
        postalCode: PropTypes.string,
        phoneNumber: PropTypes.string,
        emailAddress: PropTypes.string,
      }),
    }),
  }),
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowClientBillingContactDrawer: PropTypes.func.isRequired,
}

export default ClientBillingContactDrawer
