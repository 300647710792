import Card from "@mui/material/Card"
import React from "react"
import Skeleton from "@mui/material/Skeleton"

import { useGetPaidInvoicesQuery } from "../../slice"
import PaidInvoiceList from "./components/paid-invoice-list"

function PaidInvoices() {
  const { data: paidInvoices, isFetching } = useGetPaidInvoicesQuery()

  return (
    <Card variant="elevation" square sx={(theme) => ({ borderTop: `1px solid ${theme.palette.strokeDefault.main}` })}>
      {!paidInvoices || isFetching ? <Skeleton variant="rectangular" width="100%" height={100} /> : <PaidInvoiceList invoices={paidInvoices} maxRows={15} />}
    </Card>
  )
}

export default PaidInvoices
