import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { persistReducer } from "redux-persist"
import storageSession from "redux-persist/lib/storage/session"
import alertReducer from "./components/alert/slice"
import auditsReducer from "./components/audits/slice"
import authenticatorReducer from "./components/authenticator/slice"
import restApiSlice from "./services/rest-api/slice"
import drawerAlertReducer from "./components/drawer-alert/slice"
import rtkQueryActionHandler from "./middleware/rtk-query-action-handler"

const persistConfig = {
  key: "root",
  version: 1,
  storage: storageSession,
  blacklist: [restApiSlice.reducerPath],
}

const combinedReducers = combineReducers({
  drawer: drawerAlertReducer,
  alert: alertReducer,
  audits: auditsReducer,
  authenticator: authenticatorReducer,
  restApi: restApiSlice.reducer,
})

const rootReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    return combinedReducers(undefined, action)
  }
  return combinedReducers(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(restApiSlice.middleware, rtkQueryActionHandler),
})

export default store
