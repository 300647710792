// REACT
import React, { useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import LoadingButton from "@mui/lab/LoadingButton"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import AddCreditDrawer from "./components/add-credit-drawer"
import ExportInvoicesDrawer from "./components/export-invoices-drawer"
import INVOICES_STATUS from "./constants"
import MoreMenu from "../more-menu"
import PaidInvoices from "./components/paid-invoices"
import ReadyToInvoice from "./components/ready-to-invoice"
import SoeConfirmationDialog from "../../soe-theme/src/components/soe-confirmation-dialog"
import SoeConfirmationDialogActions from "../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-actions"
import SoeConfirmationDialogContainer from "../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-container"
import SoeConfirmationDialogContent from "../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-content"
import SoeConfirmationDialogHeader from "../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-header"
import UnPaidInvoices from "./components/unpaid-invoices"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../soe-theme/src/components"
import { useCreateUsersInvoiceMutation } from "./slice"

function Invoices() {
  const { t } = useTranslation("invoices")
  const customTheme = useTheme()

  const [invoicesStatus, setInvoicesStatus] = useState(INVOICES_STATUS.UNPAID_INVOICES)
  const [showCreditDrawer, setShowCreditDrawer] = useState(false)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [showExportInvoicesDrawer, setShowExportInvoicesDrawer] = useState(false)
  const [onSubmitEvent, setSubmitEvent] = useState()
  const [isSleeping, setIsSleeping] = useState(false)

  const [createUsersInvoice, { isLoading }] = useCreateUsersInvoiceMutation()

  const sleep = (ms) => {
    return new Promise((resolve) => {
      setIsSleeping(true)
      setTimeout(() => {
        setIsSleeping(false)
        resolve()
      }, ms)
    })
  }

  const handleInvoiceAllClick = () => {
    setShowConfirmationDialog(true)
  }

  const handleAddCreditClick = () => {
    setSubmitEvent(undefined)
    setShowCreditDrawer(true)
  }

  const handleClose = () => {
    setShowConfirmationDialog(false)
  }

  const handleConfirm = () => {
    createUsersInvoice().then(() => {
      sleep(7000).then(() => {
        setShowConfirmationDialog(false)
      })
    })
  }

  const sideMenuOptionList = [
    {
      label: t("invoicesStatus.readyToInvoice.userGroup.sideMenu.addCredit"),
      handleClick: handleAddCreditClick,
    },
  ]

  return (
    <>
      <Box component="div">
        <Card variant="elevation" square sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.strokeDefault.main}` })}>
          <CardHeader title={t("title")} />
        </Card>
        <Card variant="elevation" square>
          <Box component="div" display="flex" justifyContent="space-between" alignItems="center">
            <Box component="span">
              <Tabs
                value={invoicesStatus}
                onChange={(_, value) => (value !== null ? setInvoicesStatus(value) : invoicesStatus)}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: customTheme.palette.contentActionDefault.main,
                  },
                }}
              >
                <Tab value={INVOICES_STATUS.READY_TO_INVOICE} label={t("invoicesStatus.readyToInvoice.label")} sx={{ textTransform: "capitalize" }} />
                <Tab value={INVOICES_STATUS.UNPAID_INVOICES} label={t("invoicesStatus.unpaidInvoices.label")} sx={{ textTransform: "capitalize" }} />
                <Tab value={INVOICES_STATUS.PAID_INVOICES} label={t("invoicesStatus.paidInvoices.label")} sx={{ textTransform: "capitalize" }} />
              </Tabs>
            </Box>
            <Box component="span" mr={customTheme.utils.pxToThemeSpacing(2)}>
              {invoicesStatus === INVOICES_STATUS.READY_TO_INVOICE && (
                <LoadingButton variant="contained" onClick={handleInvoiceAllClick} loading={isLoading || isSleeping} sx={(theme) => ({ mr: theme.spacing(2) })}>
                  {t("invoicesStatus.actions.invoiceAll")}
                </LoadingButton>
              )}
              <LoadingButton variant="contained" onClick={() => setShowExportInvoicesDrawer(true)} loading={isLoading} sx={(theme) => ({ mr: theme.spacing(2) })}>
                {t("invoicesStatus.actions.exportInvoices")}
              </LoadingButton>
              {invoicesStatus === INVOICES_STATUS.READY_TO_INVOICE && <MoreMenu optionsList={sideMenuOptionList} />}
            </Box>
          </Box>
        </Card>
        {invoicesStatus === INVOICES_STATUS.READY_TO_INVOICE && <ReadyToInvoice />}
        {invoicesStatus === INVOICES_STATUS.UNPAID_INVOICES && <UnPaidInvoices />}
        {invoicesStatus === INVOICES_STATUS.PAID_INVOICES && <PaidInvoices />}
      </Box>
      <SoeDrawer showDrawer={showCreditDrawer} setShowDrawer={setShowCreditDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("addCreditDrawer.title")}
            setShowDrawer={() => {
              setShowCreditDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <AddCreditDrawer onSubmitEvent={onSubmitEvent} setShowCreditDrawer={setShowCreditDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("addCreditDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
      <ExportInvoicesDrawer showExportInvoicesDrawer={showExportInvoicesDrawer} setShowExportInvoicesDrawer={setShowExportInvoicesDrawer} />
      <SoeConfirmationDialog showConfirmationDialog={showConfirmationDialog} setShowConfirmationDialog={setShowConfirmationDialog}>
        <SoeConfirmationDialogContainer>
          <SoeConfirmationDialogHeader
            title={t("invoiceAllConfirmationDialog.title")}
            setShowConfirmationDialog={() => {
              setShowConfirmationDialog(false)
            }}
          />
          <SoeConfirmationDialogContent>
            <Typography variant="bodyMedium600" component="span" sx={{ textTransform: "none" }}>
              {t("invoiceAllConfirmationDialog.content")}
            </Typography>
          </SoeConfirmationDialogContent>
          <SoeConfirmationDialogActions
            buttons={[
              {
                action: handleClose,
                label: t("invoiceAllConfirmationDialog.actions.cancel"),
                variant: "outlined",
                disabled: isLoading,
              },
              {
                action: handleConfirm,
                label: t("invoiceAllConfirmationDialog.actions.confirm"),
                variant: "contained",
                loading: isLoading || isSleeping,
              },
            ]}
          />
        </SoeConfirmationDialogContainer>
      </SoeConfirmationDialog>
    </>
  )
}
export default Invoices
