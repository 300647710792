import isEmpty from "lodash/isEmpty"
import PropTypes from "prop-types"
import React from "react"
import Skeleton from "@mui/material/Skeleton"
import Stack from "@mui/material/Stack"

import { useTranslation } from "react-i18next"

import BrokerCard from "../../../../../../../../../../../broker-card"
import BuyerCard from "../../../../../../../../../../../buyer-card"
import CarrierDetailsCard from "../../../../../../../../../../../carrier-details-card"
import CommercialInvoiceCard from "../../../../../../../../../../../commercial-invoice-card"
import CostSummaryCard from "../../../../../../../../../../../cost-summary-card"
import FreightOptionsCard from "../../../../../../../../../../../freight-options-card"
import InternationalOptionsCard from "../../../../../../../../../../../international-options-card"
import OrderNumberCard from "../../../../../../../../../../../order-number-card"
import PackagesCard from "../../../../../../../../../../../packages-card"
import OrderPaymentsCard from "../../../../../../../../../../../order-payments-card"
import PriceSummaryCard from "../../../../../../../../../../../price-summary-card"
import RecipientCard from "../../../../../../../../../../../recipient-card"
import ShipperCard from "../../../../../../../../../../../shipper-card"
import ShippingOptionsCard from "../../../../../../../../../../../shipping-options-card"
import TrackingNumberCard from "../../../../../../../../../../../tracking-number-card"

import { pxToThemeSpacing } from "../../../../../../../../../../../../soe-theme/src/utils"
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../../../../../../../soe-theme/src/components"
import { useAddAuditItemsManualMatchOrderMutation } from "../../../../../../../../../../slice"
import { useGetOrderQuery } from "../../../../../../../../../../../../services/order/slice"

function OrderDetailsDrawer({ orderId, auditId, auditItemId, showOrderDetailsDrawer, setShowOrderDetailsDrawer }) {
  const { t } = useTranslation("audits")

  const { data: order } = useGetOrderQuery(orderId)
  const [addAuditItemsManualMatchOrder, { isLoading, isSuccess }] = useAddAuditItemsManualMatchOrderMutation()

  const handleMatchClick = async () => {
    if (auditId && auditItemId) await addAuditItemsManualMatchOrder({ auditId, auditItemId, orderId })
    if (isSuccess) setShowOrderDetailsDrawer(false)
  }

  const isInternationalOptions = (internationalOptions) => {
    let result = false
    Object.keys(internationalOptions).forEach((key) => {
      if (key !== "legacy" && key !== "toConvert") {
        result = true
      }
    })
    return result
  }

  return (
    <SoeDrawer showDrawer={showOrderDetailsDrawer} setShowDrawer={setShowOrderDetailsDrawer} anchor="right" keepMounted={false} preventClosing={isLoading}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("auditDetails.auditItemDetails.orderSearch.orderDetailsDrawer.title")}
          setShowDrawer={() => {
            setShowOrderDetailsDrawer(false)
          }}
          preventClosing={isLoading}
        />
        <SoeDrawerContent>
          {!order ? (
            <Skeleton variant="rectangular" height={50} width="100%" />
          ) : (
            <Stack direction="column" spacing={pxToThemeSpacing(4)}>
              <OrderNumberCard orderNumber={order.number} />
              <TrackingNumberCard trackingNumber={order.tracking.masterId} />
              <CarrierDetailsCard service={order.quotation.response.service} carrier={order.quotation.response.carrier} charges={order.quotation.response.rate.charges} />
              <CostSummaryCard rate={order.quotation.response.rate} />
              <PriceSummaryCard rate={order.quotation.response.rate} />
              {order.orderStatus === "prepaid" && <OrderPaymentsCard order={order} />}
              <ShipperCard shipper={order.quotation.request.shipper} showPhoneNumber showEmailAddress />
              <RecipientCard recipient={order.quotation.request.recipient} showPhoneNumber showEmailAddress />
              <PackagesCard packages={order.quotation.request.packages} />
              <ShippingOptionsCard shippingOptions={order.quotation.request.shippingOptions} />
              {!isEmpty(order.quotation.request.freightOptions) && <FreightOptionsCard freightOptions={order.quotation.request.freightOptions} />}
              {!isEmpty(order.quotation.request.internationalOptions) && isInternationalOptions(order.quotation.request.internationalOptions) && <InternationalOptionsCard internationalOptions={order.quotation.request.internationalOptions} />}
              {!isEmpty(order.quotation.request.broker) && <BrokerCard broker={order.quotation.request.broker} />}
              {!isEmpty(order.quotation.request.commercialInvoice) && <CommercialInvoiceCard commercialInvoice={order.quotation.request.commercialInvoice} />}
              {!isEmpty(order.quotation.request.buyer) && <BuyerCard buyer={order.quotation.request.buyer} />}
            </Stack>
          )}
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={[
            {
              action: handleMatchClick,
              label: t("auditDetails.auditItemDetails.orderSearch.orderDetailsDrawer.actions.match"),
              variant: "contained",
              loading: isLoading,
            },
          ]}
        />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

OrderDetailsDrawer.propTypes = {
  orderId: PropTypes.string.isRequired,
  auditId: PropTypes.string.isRequired,
  auditItemId: PropTypes.string.isRequired,
  showOrderDetailsDrawer: PropTypes.bool.isRequired,
  setShowOrderDetailsDrawer: PropTypes.func.isRequired,
}

export default OrderDetailsDrawer
