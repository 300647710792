// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"

import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

// MUI
import Chip from "@mui/material/Chip"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"

// Others
import MoreMenu from "../../../more-menu"
import SoeConfirmationDialog from "../../../../soe-theme/src/components/soe-confirmation-dialog"
import SoeConfirmationDialogActions from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-actions"
import SoeConfirmationDialogContainer from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-container"
import SoeConfirmationDialogContent from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-content"
import SoeConfirmationDialogHeader from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-header"

import { ROUTES } from "../../../../constant"
import { useEnableUserMutation, useDisableUserMutation } from "../../../../services/user/slice"

import BILLING_TYPE_ENUM from "../../../../utils/billing"

function Client({ client, externalHandleClientClick, showMoreMenu }) {
  const { t } = useTranslation("clients")
  const navigate = useNavigate()

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

  const [enableUser] = useEnableUserMutation()
  const [disableUser] = useDisableUserMutation()

  const handleClientClick = () => {
    navigate(`${ROUTES.CLIENTS}/${client.emailAddress}`)
  }

  const handleAssignClientClick = () => {
    externalHandleClientClick(client)
  }

  const handleChangeClientStatus = () => {
    setShowConfirmationDialog(true)
  }

  const handleClose = () => {
    setShowConfirmationDialog(false)
  }

  const handleConfirm = () => {
    if (client.disabled) enableUser(client.emailAddress)
    else disableUser(client.emailAddress)
    setShowConfirmationDialog(false)
  }

  const sideMenuOptionList = [
    {
      label: t("sideMenu.details"),
      handleClick: handleClientClick,
    },
    {
      label: client.disabled ? t("sideMenu.enable") : t("sideMenu.disable"),
      handleClick: handleChangeClientStatus,
    },
  ]

  return (
    <>
      <SoeConfirmationDialog showConfirmationDialog={showConfirmationDialog} setShowConfirmationDialog={setShowConfirmationDialog}>
        <SoeConfirmationDialogContainer>
          <SoeConfirmationDialogHeader
            title={client.disabled ? t("confirmationDialog.title.label.enable") : t("confirmationDialog.title.label.disable")}
            setShowConfirmationDialog={() => {
              setShowConfirmationDialog(false)
            }}
          />
          <SoeConfirmationDialogContent>
            <Typography variant="bodyMedium600" component="span" sx={{ textTransform: "none" }}>
              {client.disabled ? t("confirmationDialog.content.label.enable") : t("confirmationDialog.content.label.disable")}
            </Typography>
          </SoeConfirmationDialogContent>
          <SoeConfirmationDialogActions
            buttons={[
              {
                action: handleClose,
                label: t("confirmationDialog.action.cancel.label"),
                variant: "outlined",
              },
              {
                action: handleConfirm,
                label: t("confirmationDialog.action.confirm.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeConfirmationDialogContainer>
      </SoeConfirmationDialog>

      <TableRow hover component="tr" onClick={!externalHandleClientClick ? handleClientClick : handleAssignClientClick} data-client={client} sx={(theme) => ({ backgroundColor: theme.palette.contentNeutralPrimaryReverse.main })}>
        <TableCell component="td" scope="row">
          {client.clientAddress?.personName}
        </TableCell>
        <TableCell component="td" scope="row">
          {client.emailAddress}
        </TableCell>
        <TableCell component="td" scope="row">
          {client.clientAddress?.companyName}
        </TableCell>
        <TableCell component="td" scope="row">
          {client.clientAddress?.addressLine1}
          {client.clientAddress?.addressLine2 && ", ".concat(client.clientAddress?.addressLine2)}
          {client.clientAddress?.city && ", ".concat(client.clientAddress?.city)}
          {client.clientAddress?.provinceCode && ", ".concat(client.clientAddress?.provinceCode)}
          {client.clientAddress?.countryCode && ", ".concat(client.clientAddress?.countryCode)}
          {client.clientAddress?.postalCode && ", ".concat(client.clientAddress?.postalCode)}
        </TableCell>
        <TableCell component="td" scope="row">
          {client.pricingGroupName}
        </TableCell>
        <TableCell component="td" scope="row">
          {client.billing?.currency}
        </TableCell>
        <TableCell component="td" scope="row">
          {client.billing?.type === BILLING_TYPE_ENUM.ACCOUNT && <Chip label={t(`headers.billingTypes.${client?.billing?.type}`)} size="small" color="primary" sx={(theme) => ({ color: theme.palette.contentNeutralPrimaryReverse.main })} />}
          {client.billing?.type === BILLING_TYPE_ENUM.CREDIT_CARD && <Chip label={t(`headers.billingTypes.${client?.billing?.type}`)} size="small" color="secondary" sx={(theme) => ({ color: theme.palette.contentNeutralPrimaryReverse.main })} />}
        </TableCell>
        <TableCell component="td" scope="row">
          <Chip label={client.disabled ? t("clientStatus.inactive") : t("clientStatus.active")} size="small" color={client.disabled ? "error" : "success"} sx={(theme) => ({ color: theme.palette.contentNeutralPrimaryReverse.main })} />
        </TableCell>
        {showMoreMenu && (
          <TableCell component="td" scope="row" align="right">
            <MoreMenu optionsList={sideMenuOptionList} />
          </TableCell>
        )}
      </TableRow>
    </>
  )
}

Client.propTypes = {
  client: PropTypes.shape({
    disabled: PropTypes.bool,
    clientAddress: PropTypes.shape({
      personName: PropTypes.string,
      companyName: PropTypes.string,
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      city: PropTypes.string,
      provinceCode: PropTypes.string,
      countryCode: PropTypes.string,
      postalCode: PropTypes.string,
    }),
    emailAddress: PropTypes.string,
    billing: PropTypes.shape({
      type: PropTypes.string,
      currency: PropTypes.string,
    }),
    pricingGroupName: PropTypes.string,
  }).isRequired,
  externalHandleClientClick: PropTypes.func,
  showMoreMenu: PropTypes.bool.isRequired,
}

Client.defaultProps = {
  externalHandleClientClick: undefined,
}

export default Client
