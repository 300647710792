import Button from "@mui/material/Button"
import LoadingButton from "@mui/lab/LoadingButton"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import Stack from "@mui/material/Stack"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"

import { useNavigate } from "react-router-dom"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import AuditErrorsDrawer from "../audit-errors-drawer"
import CarrierServiceLogo from "../../../carrier-service-logo"
import CustomChip from "./style"
import DateTimeService from "../../../../services/date-time"
import SoeConfirmationDialog from "../../../../soe-theme/src/components/soe-confirmation-dialog"
import SoeConfirmationDialogContainer from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-container"
import SoeConfirmationDialogHeader from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-header"
import SoeConfirmationDialogContent from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-content"
import SoeConfirmationDialogActions from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-actions"

import { AuditProps } from "../../../../models/audit"
import { AuditStatusEnum, AuditErrorEnum } from "../../constant"
import { pxToRem } from "../../../../soe-theme/src/typography"
import { ROUTES } from "../../../../constant"
import { useDeleteAuditFileMutation, useRedoAuditFileMutation } from "../../slice"
import { useGetCarrierChargesQuery, useGetCarrierServicesQuery } from "../../../carriers/slice"

function Audit({ audit, setRefreshAudits }) {
  const { t } = useTranslation("audits")
  const customTheme = useTheme()
  const navigate = useNavigate()

  const [showAuditErrorsDrawer, setShowAuditErrorsDrawer] = useState(false)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [filteredErrors, setFilteredErrors] = useState(audit.errors || [])
  const [showSpinner, setShowSpinner] = useState(false)

  const { data: services } = useGetCarrierServicesQuery({ code: audit.carrier?.code }, { skip: !audit.carrier?.code || !audit.errors })
  const { data: charges } = useGetCarrierChargesQuery(audit.carrier?.code, { skip: !audit.carrier?.code || !audit.errors })

  const [redoAuditFile, { isLoading: redoAuditFileLoading }] = useRedoAuditFileMutation()
  const [deleteAuditFile, { isLoading: deleteAuditFileLoading }] = useDeleteAuditFileMutation()

  const reprocessWaitingDelay = 3000

  useEffect(() => {
    if (services && charges && audit.errors) {
      setFilteredErrors(
        audit.errors.filter((error) => {
          if (error.type === AuditErrorEnum.SERVICE_MAPPING_ERROR) {
            return !services?.find((service) => service.billingCode === error.billingCode || service.billingCode?.split(",").includes(error.billingCode))
          }
          if (error.type === AuditErrorEnum.CHARGE_MAPPING_ERROR) {
            return !charges?.find((charge) => charge.billingCode === error.billingCode || charge.billingCode?.split(",").includes(error.billingCode))
          }
          return true
        })
      )
    }
  }, [services, charges, audit.errors])

  useEffect(() => {
    if (redoAuditFileLoading) setShowSpinner(true)
  }, [redoAuditFileLoading])

  const handleAuditClick = () => {
    if (audit.status === AuditStatusEnum.READY) navigate(`${ROUTES.AUDITS}/${audit.id}`)
  }

  const handleDeleteAudit = () => {
    setShowConfirmationDialog(true)
  }

  const handleRedoAuditFile = () => {
    redoAuditFile(audit.id).then(() => {
      setTimeout(() => {
        setRefreshAudits(true)
        setShowSpinner(false)
      }, reprocessWaitingDelay)
    })
  }

  const handleClose = () => {
    setShowConfirmationDialog(false)
  }

  const handleConfirm = () => {
    deleteAuditFile(audit.id).then(() => {
      setRefreshAudits(true)
      setShowConfirmationDialog(false)
    })
  }

  return (
    <>
      <TableRow hover component="tr" onClick={handleAuditClick} sx={{ backgroundColor: customTheme.palette.contentNeutralPrimaryReverse.main, height: pxToRem(56) }}>
        <TableCell component="td" scope="row" align="center">
          <CarrierServiceLogo code={audit?.carrier?.code} logo={audit?.carrier?.logo} align="center" />
        </TableCell>
        <TableCell component="td" scope="row">
          {audit.filename}
        </TableCell>
        <TableCell component="td" scope="row">
          <div>{DateTimeService.toLocaleDateString(audit.createdDate)}</div>
          <div>{DateTimeService.toLocaleTimeString(audit.createdDate)}</div>
        </TableCell>
        <TableCell component="td" scope="row">
          {DateTimeService.toLocaleDateString(audit?.invoice?.date)}
        </TableCell>
        <TableCell component="td" scope="row">
          {audit.invoice?.accountNumber}
        </TableCell>
        <TableCell component="td" scope="row">
          {audit.invoice?.number}
        </TableCell>
        <TableCell component="td" scope="row">
          {audit.status}
        </TableCell>
        <TableCell component="td" scope="row">
          {filteredErrors.length > 0 && (
            <Button variant="outlined" size="small" onClick={() => setShowAuditErrorsDrawer(true)}>
              {t("showErrors")}
            </Button>
          )}
          {filteredErrors.length === 0 && audit.status === AuditStatusEnum.MAPPING_ERRORS && (
            <LoadingButton variant="outlined" size="small" onClick={handleRedoAuditFile} loading={showSpinner}>
              {t("reprocess")}
            </LoadingButton>
          )}
          {audit.status === AuditStatusEnum.FAILED && (
            <Button variant="outlined" size="small" color="error" sx={{ marginLeft: 1 }} onClick={() => handleDeleteAudit()}>
              {t("deleteAudit")}
            </Button>
          )}
          {filteredErrors.length === 0 &&
            audit.status === AuditStatusEnum.READY &&
            (audit.metadata?.totalLines === audit.metadata?.auditItemStatus?.validated ? (
              <CustomChip label={t("auditItemsCompleted")} color="success" size="small" sx={{ color: "white", width: 0.82 }} />
            ) : (
              <Stack direction="row" sx={{ display: "flex", width: 1 }}>
                <CustomChip label={audit.metadata?.auditItemStatus?.validated} color="success" size="small" sx={{ color: "white", width: 0.25 }} />
                <CustomChip label={audit.metadata?.auditItemStatus?.matched} color="warning" size="small" sx={{ color: "white", width: 0.25 }} />
                <CustomChip label={audit.metadata?.auditItemStatus?.unmatched} color="error" size="small" sx={{ width: 0.25 }} />
              </Stack>
            ))}
        </TableCell>
      </TableRow>
      {audit.errors && <AuditErrorsDrawer errors={filteredErrors} carrierCode={audit.carrier?.code} showAuditErrorsDrawer={showAuditErrorsDrawer} setShowAuditErrorsDrawer={setShowAuditErrorsDrawer} />}
      <SoeConfirmationDialog showConfirmationDialog={showConfirmationDialog} setShowConfirmationDialog={setShowConfirmationDialog}>
        <SoeConfirmationDialogContainer>
          <SoeConfirmationDialogHeader
            title={t("deleteConfirmationDialog.title")}
            setShowConfirmationDialog={() => {
              setShowConfirmationDialog(false)
            }}
          />
          <SoeConfirmationDialogContent>
            <Typography variant="bodyMedium600" component="span" sx={{ textTransform: "none" }}>
              {t("deleteConfirmationDialog.content")}
            </Typography>
          </SoeConfirmationDialogContent>
          <SoeConfirmationDialogActions
            buttons={[
              {
                action: handleClose,
                label: t("deleteConfirmationDialog.actions.cancel"),
                variant: "outlined",
                disabled: deleteAuditFileLoading,
              },
              {
                action: handleConfirm,
                label: t("deleteConfirmationDialog.actions.confirm"),
                variant: "contained",
                loading: deleteAuditFileLoading,
              },
            ]}
          />
        </SoeConfirmationDialogContainer>
      </SoeConfirmationDialog>
    </>
  )
}

Audit.propTypes = {
  audit: AuditProps.isRequired,
  setRefreshAudits: PropTypes.func.isRequired,
}

export default Audit
