const AppConfig = {
  app: {
    version: "2.0.0",
    environment: "QA",
    nodeEnv: "test",
  },
  aws: {
    cognito: {
      userPoolId: "us-east-2_0rCNIJ2DJ",
      clientId: "6b89nr4n91sbbah0blil28dq5q",
      hostedUiUrl: "https://qasaveonexpress.auth.us-east-2.amazoncognito.com",
    },
    restApi: {
      apiUrl: "https://api.qa.saveonexpress.ca",
    },
    graphql: {
      apiUrl: process.env.REACT_APP_GRAPHQL_API_URL,
      apiKey: process.env.REACT_APP_GRAPHQL_API_KEY,
      region: process.env.REACT_APP_GRAPHQL_API_REGION,
    },
  },
  features: {
    showOrderTypeColumn: "false" === "true",
    emailInvoiceCCList: "finance@inex.ca",
  },
  logRocket: {
    id: "ylvrtg/soe",
  },
}

export default AppConfig
