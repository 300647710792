import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import LogRocket from "logrocket"
import UserPool from "../../services/user-pool"
import AppConfig from "../../utils/app-config"

export const signIn = createAsyncThunk("auth/signIn", async (formValues) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: formValues.email,
      Pool: UserPool,
      Storage: window.sessionStorage,
    })
    user.authenticateUser(
      new AuthenticationDetails({
        Username: formValues.email,
        Password: formValues.password,
      }),
      {
        onSuccess: (data) => {
          resolve(data)
        },
        onFailure: (error) => {
          reject(error)
        },
        newPasswordRequired: (userAttributes) => {
          resolve({ setNewPassword: true, ...userAttributes, user })
        },
      }
    )
  })
})

export const signOut = createAsyncThunk("auth/signOut", async () => {
  const user = UserPool.getCurrentUser()
  if (user) user.signOut()
})

export const forgotPassword = createAsyncThunk("auth/forgotPassword", async (formValues) => {
  return new Promise((resolve, reject) => {
    new CognitoUser({
      Username: formValues.email,
      Pool: UserPool,
      Storage: window.sessionStorage,
    }).forgotPassword({
      onFailure(err) {
        reject(err)
      },
      onSuccess() {
        resolve(formValues.email)
      },
      inputVerificationCode() {
        resolve(formValues.email)
      },
    })
  })
})

export const forgotPasswordSubmit = createAsyncThunk("auth/forgotPasswordSubmit", async (formValues) => {
  return new Promise((resolve, reject) => {
    new CognitoUser({
      Username: formValues.email,
      Pool: UserPool,
      Storage: window.sessionStorage,
    }).confirmPassword(formValues.verificationCode, formValues.newPassword, {
      onSuccess: (data) => {
        resolve(data)
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
})

export const changePassword = createAsyncThunk("auth/changePassword", async (formValues) => {
  return new Promise((resolve, reject) => {
    const currentUser = UserPool.getCurrentUser()
    if (currentUser) {
      currentUser.getSession((err) => {
        if (err) {
          reject(err)
        } else {
          currentUser.changePassword(formValues.oldPassword, formValues.newPassword, (error, res) => {
            if (error) {
              reject(error)
            } else {
              resolve(res)
            }
          })
        }
      })
    } else {
      reject(new Error("User is not authenticated"))
    }
  })
})

export const currentAuthenticatedUser = createAsyncThunk("auth/currentAuthenticatedUser", async () => {
  return new Promise((resolve, reject) => {
    const user = UserPool.getCurrentUser()
    if (user) {
      user.getSession(async (err) => {
        if (err) {
          reject(err)
        } else {
          user.getUserAttributes((error, attributes) => {
            if (error) {
              reject(error)
            } else {
              const email = attributes?.find((attribute) => attribute.Name === "email")?.Value
              if (email && AppConfig.logRocket?.id) {
                LogRocket.identify(email, {
                  Email: email,
                  AppVersion: AppConfig.app.version,
                })
              }
              const results = {}
              attributes.forEach((attribute) => {
                const { Name, Value } = attribute
                results[Name] = Value
              })
              resolve(results)
            }
          })
        }
      })
    } else {
      reject(new Error("User is not authenticated"))
    }
  })
})

export const authenticatorSlice = createSlice({
  name: "authenticator",
  initialState: {
    formState: "signIn",
    showAuthenticator: false,
    email: undefined,
  },
  reducers: {
    showForgotPassword: (state) => {
      state.formState = "forgotPassword"
    },
    showSignIn: (state) => {
      state.formState = "signIn"
    },
    setShowAuthenticator: (state, action) => {
      state.showAuthenticator = action.payload
    },
  },
  extraReducers: {
    [currentAuthenticatedUser.fulfilled]: (state, action) => {
      if (action.payload !== undefined) {
        state.formState = "signedIn"
      } else {
        state.formState = "signIn"
      }
    },
    [signIn.fulfilled]: (state) => {
      state.formState = "signedIn"
      state.showAuthenticator = false
    },
    [signOut.fulfilled]: (state) => {
      state.formState = "signIn"
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.formState = "forgotPasswordSubmit"
      state.email = action.payload
    },
    [forgotPasswordSubmit.fulfilled]: (state) => {
      state.formState = "signIn"
      state.email = undefined
    },
  },
})

export const getJwtTokens = async () => {
  return new Promise((resolve, reject) => {
    let user
    try {
      user = UserPool.getCurrentUser()
    } catch (err) {
      reject(new Error("Error getting current user", err))
    }
    if (user) {
      user.getSession(async (err, session) => {
        if (err) {
          reject(new Error("Error getting session", err))
        }
        resolve({
          accessToken: session.getAccessToken().getJwtToken(),
          idToken: session.getIdToken().getJwtToken(),
        })
      })
    } else {
      reject(new Error("User is not authenticated"))
    }
  })
}

export const { setShowAuthenticator, showForgotPassword, showSignIn } = authenticatorSlice.actions

export default authenticatorSlice.reducer
