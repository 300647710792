import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Chip from "@mui/material/Chip"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import AppConfig from "../../../../../../utils/app-config"
import Currency from "../../../../../currency"
import DateTimeService from "../../../../../../services/date-time"
import EmailInvoiceDrawer from "../../../email-invoice-drawer"
import MoreMenu from "../../../../../more-menu"
import ChargePaymentMethodDrawer from "../../../charge-payment-method-drawer"
import RecordPaymentDrawer from "../../../record-payment-drawer"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../soe-theme/src/components"
import CustomChip from "../../../../../audits/components/audit/style"
import { useGetClientDetailsQuery } from "../../../../../clients/slice"

import BILLING_TYPE_ENUM from "../../../../../../utils/billing"

function UnPaidInvoice({ invoice }) {
  const { t } = useTranslation("invoices")
  const customTheme = useTheme()

  const { data: clientDetails } = useGetClientDetailsQuery(invoice?.user?.emailAddress)

  const [onSubmitEvent, setSubmitEvent] = useState()
  const [showChargeCardDrawer, setShowChargeCardDrawer] = useState(false)
  const [showRecordPaymentDrawer, setShowRecordPaymentDrawer] = useState(false)
  const [showEmailInvoiceDrawer, setShowEmailInvoiceDrawer] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleAddRecordPaymentClick = () => {
    setSubmitEvent(undefined)
    setShowRecordPaymentDrawer(true)
  }

  const handlePrintClick = () => {
    window.open(`${AppConfig.aws.restApi.apiUrl}/invoices/print/${invoice.id}`, "_blank")
  }

  const handleEmailInvoiceClick = () => {
    setShowEmailInvoiceDrawer(true)
  }

  const handleChargeCardClick = () => {
    setShowChargeCardDrawer(true)
  }

  const userHasValidPaymentMethods = () => {
    const validPaymentMethods = clientDetails?.paymentMethods?.filter((paymentMethod) => paymentMethod.status !== "deleted")
    return validPaymentMethods?.length > 0
  }

  const isOverdue = () => new Date(invoice.dueDate) < new Date()

  const sideMenuOptionList = () => {
    const options = [
      {
        label: t("unpaidInvoiceList.optionsList.recordPayment"),
        handleClick: handleAddRecordPaymentClick,
      },
      {
        label: t("unpaidInvoiceList.optionsList.print"),
        handleClick: handlePrintClick,
      },
      {
        label: t("unpaidInvoiceList.optionsList.emailInvoice"),
        handleClick: handleEmailInvoiceClick,
      },
    ]
    if (userHasValidPaymentMethods()) {
      options.unshift({
        label: t("unpaidInvoiceList.optionsList.chargePaymentMethod"),
        handleClick: handleChargeCardClick,
      })
    }
    return options
  }

  const handleClose = () => {
    setShowRecordPaymentDrawer(false)
  }

  return (
    <>
      <TableRow hover component="tr" sx={{ backgroundColor: customTheme.palette.contentNeutralPrimaryReverse.main }}>
        <TableCell component="td" scope="row">
          <Typography>{invoice.invoiceNumber}</Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography>{DateTimeService.toLocaleDateString(invoice.createdDate)}</Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography color={isOverdue() ? "red" : ""}>{DateTimeService.toLocaleDateString(invoice.dueDate)}</Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography component="div">
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
              <Typography>
                <Typography noWrap sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
                  {invoice.user?.clientAddress?.companyName ? invoice.user.clientAddress.companyName : invoice.user.clientAddress.personName}
                </Typography>
                {invoice.user?.clientAddress?.companyName ? ` (${invoice.user.clientAddress.personName})` : ""}
              </Typography>
            </Box>
            <Typography noWrap variant="bodySmall400">
              {invoice.user?.emailAddress}
            </Typography>
          </Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, fontSize: theme.utils.pxToRem(20) })}>
            <Currency amount={invoice.summary?.invoice?.total} currency={invoice.currency} currencyPosition="right" showSymbol={false} />
          </Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, fontSize: theme.utils.pxToRem(20) })}>
            {invoice.summary?.amountPaid !== 0 && <Currency amount={invoice.summary?.amountPaid} currency={invoice.currency} currencyPosition="right" showSymbol={false} />}
          </Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, fontSize: theme.utils.pxToRem(20) })}>
            {invoice.summary?.amountOutstanding !== 0 && <Currency amount={invoice.summary?.amountOutstanding} currency={invoice.currency} currencyPosition="right" showSymbol={false} />}
          </Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          {invoice.user.billing?.type === BILLING_TYPE_ENUM.ACCOUNT && (
            <Chip label={t(`invoicesStatus.readyToInvoice.userGroup.${invoice.user?.billing?.type}`)} size="small" color="primary" sx={(theme) => ({ color: theme.palette.contentNeutralPrimaryReverse.main })} />
          )}
          {invoice.user.billing?.type === BILLING_TYPE_ENUM.CREDIT_CARD && (
            <Chip label={t(`invoicesStatus.readyToInvoice.userGroup.${invoice.user?.billing?.type}`)} size="small" color="secondary" sx={(theme) => ({ color: theme.palette.contentNeutralPrimaryReverse.main })} />
          )}
        </TableCell>
        <TableCell component="td" scope="row">
          {invoice?.sentByEmail && <CustomChip label={t("unpaidInvoiceList.emailSent")} color="success" size="small" sx={{ color: "white" }} />}
        </TableCell>
        <TableCell component="td" scope="row" align="right">
          <MoreMenu optionsList={sideMenuOptionList()} />
        </TableCell>
      </TableRow>
      {showChargeCardDrawer && (
        <SoeDrawer showDrawer={showChargeCardDrawer} setShowDrawer={setShowChargeCardDrawer} anchor="right" keepMounted={false}>
          <SoeDrawerContainer>
            <SoeDrawerHeader
              title={t("chargePaymentMethod.title")}
              setShowDrawer={() => {
                setShowChargeCardDrawer(false)
              }}
            />
            <SoeDrawerContent>
              <ChargePaymentMethodDrawer invoice={invoice} onSubmitEvent={onSubmitEvent} setShowDrawer={setShowChargeCardDrawer} setIsLoading={setIsLoading} />
            </SoeDrawerContent>
            <SoeDrawerActions
              buttons={[
                {
                  action: handleClose,
                  label: t("chargePaymentMethod.actions.cancel.label"),
                  variant: "outlined",
                  color: "error",
                },
                {
                  action: setSubmitEvent,
                  label: t("chargePaymentMethod.actions.save.label"),
                  variant: "contained",
                  loading: isLoading,
                },
              ]}
            />
          </SoeDrawerContainer>
        </SoeDrawer>
      )}
      {showRecordPaymentDrawer && (
        <SoeDrawer showDrawer={showRecordPaymentDrawer} setShowDrawer={setShowRecordPaymentDrawer} anchor="right" keepMounted={false}>
          <SoeDrawerContainer>
            <SoeDrawerHeader
              title={t("recordPaymentDrawer.title")}
              setShowDrawer={() => {
                setShowRecordPaymentDrawer(false)
              }}
            />
            <SoeDrawerContent>
              <RecordPaymentDrawer invoice={invoice} onSubmitEvent={onSubmitEvent} setShowRecordPaymentDrawer={setShowRecordPaymentDrawer} setIsLoading={setIsLoading} />
            </SoeDrawerContent>
            <SoeDrawerActions
              buttons={[
                {
                  action: handleClose,
                  label: t("recordPaymentDrawer.actions.cancel.label"),
                  variant: "outlined",
                  color: "error",
                },
                {
                  action: setSubmitEvent,
                  label: t("recordPaymentDrawer.actions.save.label"),
                  variant: "contained",
                  loading: isLoading,
                },
              ]}
            />
          </SoeDrawerContainer>
        </SoeDrawer>
      )}
      {showEmailInvoiceDrawer && <EmailInvoiceDrawer invoice={invoice} showEmailInvoiceDrawer={showEmailInvoiceDrawer} setShowEmailInvoiceDrawer={setShowEmailInvoiceDrawer} />}
    </>
  )
}

UnPaidInvoice.propTypes = {
  invoice: PropTypes.instanceOf(Object).isRequired,
}

export default UnPaidInvoice
