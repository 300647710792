import isEmpty from "lodash/isEmpty"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import Skeleton from "@mui/material/Skeleton"
import Stack from "@mui/material/Stack"

import { useTranslation } from "react-i18next"

import { useDispatch } from "react-redux"
import AuditInfoCard from "../../../audit-info-card"
import BuyerCard from "../../../buyer-card"
import CarrierDetailsCard from "../../../carrier-details-card"
import CommercialInvoiceCard from "../../../commercial-invoice-card"
import BrokerCard from "../../../broker-card"
import FreightOptionsCard from "../../../freight-options-card"
import InternationalOptionsCard from "../../../international-options-card"
import OrderNumberCard from "../../../order-number-card"
import PackagesCard from "../../../packages-card"
import PriceSummaryCard from "../../../price-summary-card"
import RecipientCard from "../../../recipient-card"
import ShipperCard from "../../../shipper-card"
import ShippingOptionsCard from "../../../shipping-options-card"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"
import TrackingNumberCard from "../../../tracking-number-card"
import { pxToThemeSpacing } from "../../../../soe-theme/src/utils"
import TransactionStatus from "../../../transaction-status"
import { clearDrawerAlert } from "../../../drawer-alert/slice"
import DrawerAlert from "../../../drawer-alert"
import { useGetOrderQuery } from "../../../../services/order/slice"
import InvoiceNumberCard from "../../../invoice-number-card"

function OrderDetailsDrawer({ selectedOrderId, showOrderDetailsDrawer, setShowOrderDetailsDrawer, showTransactionStatus, deleteOrderResponse, isError, isLoading, actions }) {
  const { t } = useTranslation("orders")
  const dispatch = useDispatch()

  const { data: selectedOrder } = useGetOrderQuery(selectedOrderId)

  const isInternationalOptions = (internationalOptions) => {
    let result = false
    Object.keys(internationalOptions).forEach((key) => {
      if (key !== "legacy" && key !== "toConvert") {
        result = true
      }
    })
    return result
  }

  useEffect(() => {
    dispatch(clearDrawerAlert())
  }, [])

  return (
    <SoeDrawer showDrawer={showOrderDetailsDrawer} setShowDrawer={setShowOrderDetailsDrawer} anchor="right" keepMounted={false}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("orderDetailsDrawer.title")}
          setShowDrawer={() => {
            setShowOrderDetailsDrawer(false)
          }}
        />
        <SoeDrawerContent drawerScrollToTop={showTransactionStatus}>
          {selectedOrder ? (
            <Stack
              direction="column"
              spacing={pxToThemeSpacing(8)}
              sx={(theme) => ({
                marginBottom: theme.spacing(2),
              })}
            >
              <DrawerAlert />
              {showTransactionStatus && <TransactionStatus transaction={deleteOrderResponse} title={t("orderDetailsDrawer.cancel.label")} isError={isError} isLoading={isLoading} />}
              <OrderNumberCard orderNumber={selectedOrder.number} sx={(theme) => ({ marginBottom: theme.spacing(3) })} />
              {selectedOrder?.tracking?.masterId && <TrackingNumberCard trackingNumber={selectedOrder?.tracking?.masterId} />}
              {selectedOrder?.invoiceNumber && <InvoiceNumberCard invoiceNumber={selectedOrder.invoiceNumber} />}
              {selectedOrder?.audit?.filename && <AuditInfoCard audit={selectedOrder.audit} />}
              <CarrierDetailsCard
                service={selectedOrder.quotation.response.service}
                carrier={selectedOrder.quotation.response.carrier}
                commitment={selectedOrder.quotation.response.commitment}
                charges={selectedOrder.quotation.response.rate.charges}
                shippingDate={selectedOrder.quotation.request.shippingOptions.shippingDate}
                createdDate={selectedOrder.createdDate}
              />
              <PriceSummaryCard rate={selectedOrder.quotation.response.rate} />
              <ShipperCard shipper={selectedOrder.quotation.request.shipper} isEditable={false} showPhoneNumber showEmailAddress />
              <RecipientCard recipient={selectedOrder.quotation.request.recipient} isEditable={false} showPhoneNumber showEmailAddress />
              <PackagesCard packages={selectedOrder.quotation.request.packages} isEditable={false} />
              <ShippingOptionsCard shippingOptions={selectedOrder.quotation.request.shippingOptions} isEditable={false} />
              {!isEmpty(selectedOrder.quotation.request.freightOptions) && <FreightOptionsCard freightOptions={selectedOrder.quotation.request.freightOptions} isEditable={false} />}
              {!isEmpty(selectedOrder.quotation.request.internationalOptions) && isInternationalOptions(selectedOrder.quotation.request.internationalOptions) && (
                <InternationalOptionsCard internationalOptions={selectedOrder.quotation.request.internationalOptions} isEditable={false} />
              )}
              {!isEmpty(selectedOrder.quotation.request.broker) && <BrokerCard broker={selectedOrder.quotation.request.broker} isEditable={false} />}
              {!isEmpty(selectedOrder.quotation.request.commercialInvoice) && <CommercialInvoiceCard commercialInvoice={selectedOrder.quotation.request.commercialInvoice} isEditable={false} />}
              {!isEmpty(selectedOrder.quotation.request.buyer) && <BuyerCard buyer={selectedOrder.quotation.request.buyer} isEditable={false} />}
            </Stack>
          ) : (
            <Skeleton variant="rectangular" height={50} width="100%" />
          )}
        </SoeDrawerContent>
        <SoeDrawerActions buttons={actions} />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

OrderDetailsDrawer.defaultProps = {
  showTransactionStatus: false,
  isError: false,
  isLoading: false,
  actions: [],
  deleteOrderResponse: undefined,
}

OrderDetailsDrawer.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  selectedOrderId: PropTypes.string.isRequired,
  deleteOrderResponse: PropTypes.shape({}),
  showOrderDetailsDrawer: PropTypes.bool.isRequired,
  setShowOrderDetailsDrawer: PropTypes.func.isRequired,
  showTransactionStatus: PropTypes.bool,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default OrderDetailsDrawer
