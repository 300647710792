import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import CustomPagination from "../../../../../custom-pagination"
import EnhancedTableHead from "../../../../../enhanced-table-head"
import getComparator from "../../../../../../utils/sorting"
import UnPaidInvoice from "../unpaid-invoice"
import OrderProps from "../../../../../../services/order/model"

function UnPaidInvoiceList({ invoices, maxRows }) {
  const { t } = useTranslation("invoices")
  const theme = useTheme()

  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("invoiceNumber")
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(maxRows)

  const headCells = [
    {
      id: "invoiceNumber",
      disablePadding: false,
      label: t("unpaidInvoiceList.headers.invoiceNumber"),
    },
    {
      id: "createdDate",
      disablePadding: false,
      label: t("unpaidInvoiceList.headers.invoiceDate"),
    },
    {
      id: "dueDate",
      disablePadding: false,
      label: t("unpaidInvoiceList.headers.dueDate"),
    },
    {
      id: "user.clientAddress.companyName",
      disablePadding: false,
      label: t("unpaidInvoiceList.headers.client"),
    },
    {
      id: "summary.invoice.total",
      disablePadding: false,
      label: t("unpaidInvoiceList.headers.invoiceTotal"),
    },
    {
      id: "summary.amountPaid",
      disablePadding: false,
      label: t("unpaidInvoiceList.headers.totalPaid"),
    },
    {
      id: "summary.amountOutstanding",
      disablePadding: false,
      label: t("unpaidInvoiceList.headers.totalUnpaid"),
    },
    {
      id: "billingType",
      disablePadding: false,
      label: t("unpaidInvoiceList.headers.billingType"),
    },
    {
      id: "sentByEmail",
      disablePadding: false,
      label: t("unpaidInvoiceList.headers.emailStatus"),
    },
    {
      id: "menu",
      hideSortIcon: true,
    },
  ]

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  return (
    <Box sx={{ width: "auto" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table size="small" sx={{ backgroundColor: theme.palette.backgroundNeutralUnderground1.main }}>
            <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {invoices &&
                invoices
                  .slice()
                  .sort(getComparator(order, orderBy))
                  .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                  .map((currentInvoice, index) => {
                    return <UnPaidInvoice key={"".concat(index)} invoice={currentInvoice} />
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        {invoices && <CustomPagination count={Math.ceil(invoices.length / rowsPerPage)} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} showFirstButton showLastButton />}
      </Paper>
    </Box>
  )
}

UnPaidInvoiceList.propTypes = {
  invoices: PropTypes.arrayOf(OrderProps).isRequired,
  maxRows: PropTypes.number,
}

UnPaidInvoiceList.defaultProps = {
  maxRows: Infinity,
}

export default UnPaidInvoiceList
