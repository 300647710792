import Card from "@mui/material/Card"
import React from "react"
import Skeleton from "@mui/material/Skeleton"

import { useGetUnpaidInvoicesQuery } from "../../slice"
import UnPaidInvoiceList from "./components/unpaid-invoice-list"

function UnPaidInvoices() {
  const { data: unpaidInvoices, isFetching } = useGetUnpaidInvoicesQuery()

  return (
    <Card variant="elevation" square sx={(theme) => ({ borderTop: `1px solid ${theme.palette.strokeDefault.main}` })}>
      {!unpaidInvoices || isFetching ? <Skeleton variant="rectangular" width="100%" height={100} /> : <UnPaidInvoiceList invoices={unpaidInvoices} maxRows={15} />}
    </Card>
  )
}

export default UnPaidInvoices
