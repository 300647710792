import React, { useState } from "react"
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import Toolbar from "@mui/material/Toolbar"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"

import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { ROUTES } from "../../constant"
import SoeKeyboardArrowLeftIcon from "../../soe-theme/src/icons/SoeKeyboardArrowLeftIcon"
import SoeKeyboardArrowRightIcon from "../../soe-theme/src/icons/SoeKeyboardArrowRightIcon"
import SoeHomeIcon from "../../soe-theme/src/icons/SoeHomeIcon"
import SoeBusinessCenterIcon from "../../soe-theme/src/icons/SoeBusinessCenterIcon"
import SoeShoppingCartIcon from "../../soe-theme/src/icons/SoeShoppingCartIcon"
import SoeReceiptIcon from "../../soe-theme/src/icons/SoeReceiptIcon"
import SoeVerifiedUserIcon from "../../soe-theme/src/icons/SoeVerifiedUserIcon"
import SoeLocalShippingIcon from "../../soe-theme/src/icons/SoeLocalShippingIcon"
import SoeStyleIcon from "../../soe-theme/src/icons/SoeStyleIcon"
import SoeSettingsIcon from "../../soe-theme/src/icons/SoeSettingsIcon"
import SoeGroupIcon from "../../soe-theme/src/icons/SoeGroupIcon"

import { Drawer } from "./styled"

function MiniDrawer() {
  const navigate = useNavigate()
  const { t } = useTranslation("menu")

  const [showMenu, setShowMenu] = useState(false)

  const handleDrawerToogle = () => {
    setShowMenu(!showMenu)
  }

  const handleDashboardLinkClick = () => {
    navigate(ROUTES.HOME, { replace: true })
  }

  const handleUsersLinkClick = () => {
    navigate(ROUTES.USERS, { replace: true })
  }

  const handleClientsLinkClick = () => {
    navigate(ROUTES.CLIENTS, { replace: true })
  }

  const handleOrdersLinkClick = () => {
    navigate(ROUTES.ORDERS, { replace: true })
  }

  const handleInvoicesLinkClick = () => {
    navigate(ROUTES.INVOICES, { replace: true })
  }

  const handleAuditsLinkClick = () => {
    navigate(ROUTES.AUDITS, { replace: true })
  }

  const handleCarriersLinkClick = () => {
    navigate(ROUTES.CARRIERS, { replace: true })
  }

  const handlePricingLinkClick = () => {
    navigate(ROUTES.PRICING, { replace: true })
  }

  const handleSettingsLinkClick = () => {
    navigate(ROUTES.SETTINGS, { replace: true })
  }

  return (
    <Drawer variant="permanent" open={showMenu}>
      {/* We use Toolbar here as a hack, to have the same empty space of the toolBar */}
      <Toolbar />
      <List sx={{ paddingTop: 0 }}>
        <ListItemButton key="drawerToogle" onClick={handleDrawerToogle} sx={{ minHeight: 48, justifyContent: showMenu ? "initial" : "center", px: 2.5 }}>
          <ListItemIcon sx={{ minWidth: 0, mr: showMenu ? 3 : "auto", justifyContent: "center" }}>{showMenu ? <SoeKeyboardArrowLeftIcon /> : <SoeKeyboardArrowRightIcon />}</ListItemIcon>
        </ListItemButton>
        <Divider />
        <ListItemButton key="dashboard" onClick={handleDashboardLinkClick} sx={{ minHeight: 48, justifyContent: showMenu ? "initial" : "center", px: 2.5 }}>
          <ListItemIcon sx={{ minWidth: 0, mr: showMenu ? 3 : "auto", justifyContent: "center" }}>
            <SoeHomeIcon />
          </ListItemIcon>
          <ListItemText secondary={t("dashboard")} sx={{ opacity: showMenu ? 1 : 0 }} />
        </ListItemButton>
        <Divider />
        <ListItemButton key="users" onClick={handleUsersLinkClick} sx={{ minHeight: 48, justifyContent: showMenu ? "initial" : "center", px: 2.5 }}>
          <ListItemIcon sx={{ minWidth: 0, mr: showMenu ? 3 : "auto", justifyContent: "center" }}>
            <SoeGroupIcon />
          </ListItemIcon>
          <ListItemText secondary={t("users")} sx={{ opacity: showMenu ? 1 : 0 }} />
        </ListItemButton>
        <ListItemButton key="clients" onClick={handleClientsLinkClick} sx={{ minHeight: 48, justifyContent: showMenu ? "initial" : "center", px: 2.5 }}>
          <ListItemIcon sx={{ minWidth: 0, mr: showMenu ? 3 : "auto", justifyContent: "center" }}>
            <SoeBusinessCenterIcon />
          </ListItemIcon>
          <ListItemText secondary={t("clients")} sx={{ opacity: showMenu ? 1 : 0 }} />
        </ListItemButton>
        <Divider />
        <ListItemButton key="orders" onClick={handleOrdersLinkClick} sx={{ minHeight: 48, justifyContent: showMenu ? "initial" : "center", px: 2.5 }}>
          <ListItemIcon sx={{ minWidth: 0, mr: showMenu ? 3 : "auto", justifyContent: "center" }}>
            <SoeShoppingCartIcon />
          </ListItemIcon>
          <ListItemText secondary={t("orders")} sx={{ opacity: showMenu ? 1 : 0 }} />
        </ListItemButton>
        <ListItemButton key="invoices" onClick={handleInvoicesLinkClick} sx={{ minHeight: 48, justifyContent: showMenu ? "initial" : "center", px: 2.5 }}>
          <ListItemIcon sx={{ minWidth: 0, mr: showMenu ? 3 : "auto", justifyContent: "center" }}>
            <SoeReceiptIcon />
          </ListItemIcon>
          <ListItemText secondary={t("invoices")} sx={{ opacity: showMenu ? 1 : 0 }} />
        </ListItemButton>
        <ListItemButton key="audits" onClick={handleAuditsLinkClick} sx={{ minHeight: 48, justifyContent: showMenu ? "initial" : "center", px: 2.5 }}>
          <ListItemIcon sx={{ minWidth: 0, mr: showMenu ? 3 : "auto", justifyContent: "center" }}>
            <SoeVerifiedUserIcon />
          </ListItemIcon>
          <ListItemText secondary={t("audits")} sx={{ opacity: showMenu ? 1 : 0 }} />
        </ListItemButton>
        <Divider />
        <ListItemButton key="carriers" onClick={handleCarriersLinkClick} sx={{ minHeight: 48, justifyContent: showMenu ? "initial" : "center", px: 2.5 }}>
          <ListItemIcon sx={{ minWidth: 0, mr: showMenu ? 3 : "auto", justifyContent: "center" }}>
            <SoeLocalShippingIcon />
          </ListItemIcon>
          <ListItemText secondary={t("carriers")} sx={{ opacity: showMenu ? 1 : 0 }} />
        </ListItemButton>
        <ListItemButton key="pricing" onClick={handlePricingLinkClick} sx={{ minHeight: 48, justifyContent: showMenu ? "initial" : "center", px: 2.5 }}>
          <ListItemIcon sx={{ minWidth: 0, mr: showMenu ? 3 : "auto", justifyContent: "center" }}>
            <SoeStyleIcon />
          </ListItemIcon>
          <ListItemText secondary={t("pricing")} sx={{ opacity: showMenu ? 1 : 0 }} />
        </ListItemButton>
        <Divider />
        <ListItemButton key="settings" onClick={handleSettingsLinkClick} sx={{ minHeight: 48, justifyContent: showMenu ? "initial" : "center", px: 2.5 }}>
          <ListItemIcon sx={{ minWidth: 0, mr: showMenu ? 3 : "auto", justifyContent: "center" }}>
            <SoeSettingsIcon />
          </ListItemIcon>
          <ListItemText secondary={t("settings")} sx={{ opacity: showMenu ? 1 : 0 }} />
        </ListItemButton>
        <Divider />
      </List>
    </Drawer>
  )
}

export default MiniDrawer
