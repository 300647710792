// REACT
import PropTypes from "prop-types"
import React from "react"

import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Table from "@mui/material/Table"
import Skeleton from "@mui/material/Skeleton"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"

// Others
import CustomPagination from "../../../../../../../custom-pagination"
import EnhancedTableHead from "../../../../../../../enhanced-table-head"
import getComparator from "../../../../../../../../utils/sorting"
import Order from "./components/order/order"
import SearchInput from "../../../../../../../search-input"

import { pxToThemeSpacing } from "../../../../../../../../soe-theme/src/utils"
import { useGetAuditItemsOrdersQuery } from "../../../../../../slice"

function OrderSearch({ displaySearchOrderButton, setShowAssignClient }) {
  const { i18n, t } = useTranslation("audits")

  const [order, setOrder] = React.useState("desc")
  const [orderBy, setOrderBy] = React.useState("quotation.response.rate.totals.account.total.amount")
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [filteredOrders, setFilteredOrders] = React.useState()

  const { auditId, auditItemId } = useParams()

  const { data: orders, isFetching } = useGetAuditItemsOrdersQuery({ auditId, auditItemId, language: i18n.language }, { skip: !auditId || !auditItemId })

  React.useEffect(() => {
    setFilteredOrders(orders)
  }, [orders])

  const headCells = [
    {
      id: "quotation.response.service.code",
      disablePadding: false,
      label: t("auditDetails.auditItemDetails.orderSearch.headers.service"),
    },
    {
      id: "shipperSearchText",
      disablePadding: false,
      label: t("auditDetails.auditItemDetails.orderSearch.headers.shipper"),
    },
    {
      id: "recipientSearchText",
      disablePadding: false,
      label: t("auditDetails.auditItemDetails.orderSearch.headers.recipient"),
    },
    {
      id: "packagesSearchText",
      disablePadding: false,
      label: t("auditDetails.auditItemDetails.orderSearch.headers.packages"),
    },
    {
      id: "quotation.response.rate.totals.account.total.amount",
      disablePadding: false,
      label: t("auditDetails.auditItemDetails.orderSearch.headers.cost"),
    },
    {
      id: "quotation.request.shippingOptions.shippingDate",
      disablePadding: false,
      label: t("auditDetails.auditItemDetails.orderSearch.headers.shippingDate"),
    },
  ]

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  return (
    <Box p={pxToThemeSpacing(10)}>
      <Box m={pxToThemeSpacing(10)}>
        <Typography variant="bodyMedium600" gutterBottom sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
          {t("auditDetails.auditItemDetails.orderSearch.title")}
        </Typography>
      </Box>
      <Divider />
      <Box m={pxToThemeSpacing(14)}>
        <Grid container>
          <Grid item xs={10}>
            <SearchInput data={orders} setFilteredData={setFilteredOrders} width={0.6} excludeFields={["id"]} />
            {displaySearchOrderButton && <Button sx={{ ml: pxToThemeSpacing(10) }}>{t("auditDetails.auditItemDetails.orderSearch.actions.search")}</Button>}
          </Grid>
          <Grid item xs={2} sx={{ display: "flex", justifyContent: "right" }}>
            <Button onClick={() => setShowAssignClient(true)}>{t("auditDetails.auditItemDetails.orderSearch.actions.createNewOrder")}</Button>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <TableContainer>
        <Table size="small" sx={(theme) => ({ backgroundColor: theme.palette.backgroundNeutralUnderground1.main })}>
          <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
          <TableBody>
            {!filteredOrders || isFetching ? (
              <>
                {Array(3)
                  .fill()
                  .map((_, index) => {
                    return (
                      <TableRow key={"".concat(index)}>
                        <TableCell colSpan={12}>
                          <Skeleton variant="rectangular" height={50} width="100%" />
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </>
            ) : (
              filteredOrders
                .slice()
                .sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                .map((currentOrder, index) => {
                  return <Order key={"".concat(index)} order={currentOrder} auditId={auditId} auditItemId={auditItemId} showServiceName />
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {filteredOrders && <CustomPagination count={Math.ceil(filteredOrders.length / rowsPerPage)} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} showFirstButton showLastButton />}
    </Box>
  )
}

OrderSearch.propTypes = {
  displaySearchOrderButton: PropTypes.bool,
  setShowAssignClient: PropTypes.func,
}

OrderSearch.defaultProps = {
  displaySearchOrderButton: false,
  setShowAssignClient: () => {},
}

export default OrderSearch
