/* eslint-disable react/jsx-props-no-spreading */

import React from "react"
import { Alert as MuiAlert } from "@mui/material"

import { useDispatch, useSelector } from "react-redux"

import { clearAlert } from "./slice"

function Alert(props) {
  const { showAlert, severity, message } = useSelector((state) => state.alert)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(clearAlert())
  }

  return (
    <div className="alert">
      {showAlert && (
        <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={severity} {...props}>
          {message}
        </MuiAlert>
      )}
    </div>
  )
}

export default Alert
