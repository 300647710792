import restApiSlice from "../../services/rest-api/slice"
import { totalPackages, totalWeight } from "../../utils/packages"
import { getAddressSearchText } from "../../utils/search"

export const clientsRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query({
      query: () => ({ url: "/clients", method: "GET" }),
      providesTags: ["Clients"],
      transformResponse: (response) =>
        response.data.map((client) => {
          return {
            ...client,
            addressSearchText: getAddressSearchText(client.clientAddress),
            billingSearchText: client?.billing?.type || "",
          }
        }),
    }),
    addNewClient: builder.mutation({
      query: (payload) => ({ url: "/clients", method: "POST", body: payload }),
      invalidatesTags: ["Clients"],
      transformResponse: (response) => response.data,
    }),
    getClientDetails: builder.query({
      query: (clientId) => ({ url: `/clients/${clientId}`, method: "GET" }),
      transformResponse: (response) => response.data,
      providesTags: ["ClientDetails"],
    }),
    getClientDetailsStatistics: builder.query({
      query: ({ clientId, from, to }) => ({ url: `/clients/${clientId}/statistics`, params: { from, to }, method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    getClientDetailsOrders: builder.query({
      query: ({ clientId, from, to }) => ({ url: `/clients/${clientId}/orders`, params: { from, to }, method: "GET" }),
      transformResponse: (response) =>
        response.data.map((order) => {
          return {
            ...order,
            shipperSearchText: getAddressSearchText(order.quotation?.request?.shipper),
            recipientSearchText: getAddressSearchText(order.quotation?.request?.recipient),
            totalPackages: totalPackages(order?.quotation?.request?.packages?.pieces),
            totalWeight: totalWeight(order?.quotation?.request?.packages?.pieces),
          }
        }),
    }),
    getClientDetailsInvoices: builder.query({
      query: ({ clientId, from, to }) => ({ url: `/clients/${clientId}/invoices`, params: { from, to }, method: "GET" }),
      transformResponse: (response) =>
        response.data.map((invoice) => {
          return {
            ...invoice,
            dueDate: new Date(invoice?.createdDate).setDate(new Date(invoice?.createdDate).getDate() + Number(invoice?.user?.billing?.account?.paymentTermsDays)),
          }
        }),
    }),
    editClientDetails: builder.mutation({
      query: ({ clientId, payload }) => ({ url: `/clients/${clientId}`, method: "PATCH", body: payload }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["Clients", "ClientDetails", "users"],
    }),
    getCurrencies: builder.query({
      query: () => ({ url: "/international/currencies", method: "GET" }),
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useAddNewClientMutation, useGetClientDetailsQuery, useGetClientDetailsInvoicesQuery, useGetClientDetailsOrdersQuery, useGetClientDetailsStatisticsQuery, useGetClientsQuery, useEditClientDetailsMutation, useGetCurrenciesQuery } =
  clientsRestApiSlice
