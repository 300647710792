import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import React, { useState } from "react"

import { useTranslation } from "react-i18next"

import ClientList from "../client-list"
import NewClientDrawer from "./components/new-client-drawer"
import { useGetClientsQuery } from "./slice"

function Clients() {
  const { t } = useTranslation("clients")

  const [showNewClientDrawer, setShowNewClientDrawer] = useState(false)

  const { data: clients } = useGetClientsQuery()

  return (
    <>
      <Card variant="elevation" square sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.strokeDefault.main}` })}>
        <CardHeader
          title={t("title")}
          action={
            <Button variant="contained" onClick={() => setShowNewClientDrawer(true)} sx={(theme) => ({ mr: theme.spacing(1) })}>
              {t("newClient.actions.add.label")}
            </Button>
          }
        />
      </Card>
      <ClientList />
      <NewClientDrawer showNewClientDrawer={showNewClientDrawer} setShowNewClientDrawer={setShowNewClientDrawer} clients={clients} />
    </>
  )
}

export default Clients
