// REACT
import React, { useMemo, useState, useEffect } from "react"
import { Outlet } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// MUI
import Modal from "@mui/material/Modal"

// Custom style
import { useTranslation } from "react-i18next"
import { CustomAuthContainerBox, CustomOutletBox, CustomPageBox, CustomContainer, CustomMiniDrawerContainer } from "./style"

// Others
import AppConfig from "../../utils/app-config"
import Alert from "../alert"
import AppBar from "../app-bar"
import Authenticator from "../authenticator"
import Menu from "../menu"
import UserContext from "../../services/user/context"

import { setShowAuthenticator, showSignIn } from "../authenticator/slice"

function App() {
  const { i18n } = useTranslation()

  const { showAuthenticator } = useSelector((state) => state.authenticator)
  const dispatch = useDispatch()

  const [currentUser, setCurrentUser] = useState()

  const { formState } = useSelector((state) => state.authenticator)

  const handleAuthenticatorClose = () => {
    dispatch(setShowAuthenticator(false))
    if (formState !== "forgotPasswordSubmit") dispatch(showSignIn())
  }

  useEffect(() => {
    if (currentUser?.defaultLanguage) i18n.changeLanguage(currentUser?.defaultLanguage)
  }, [currentUser])

  useEffect(() => {
    if (AppConfig.app.environment.toLowerCase() !== "prod") document.title = `[${AppConfig.app.environment}] SaveOnExpress Admin`
  }, [])

  return (
    <CustomContainer maxWidth={false} disableGutters fixed={false} id="container-root">
      <UserContext.Provider value={useMemo(() => ({ currentUser, setCurrentUser }), [currentUser])}>
        <CustomPageBox component="div">
          <AppBar setShowAuthenticator={setShowAuthenticator} />
          <CustomMiniDrawerContainer>
            {currentUser && <Menu />}
            <CustomOutletBox component="div">
              <Alert />
              <Outlet />
            </CustomOutletBox>
          </CustomMiniDrawerContainer>
        </CustomPageBox>
        <Modal open={showAuthenticator} onClose={handleAuthenticatorClose}>
          <CustomAuthContainerBox className="authContainer" component="div">
            <Authenticator />
          </CustomAuthContainerBox>
        </Modal>
      </UserContext.Provider>
    </CustomContainer>
  )
}

export default App
