// REACT
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"

import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

// Theme
import { createAction } from "@reduxjs/toolkit"
import { useTheme } from "@mui/material/styles"

// Others
import AppConfig from "../../utils/app-config"
import AppInfo from "./components/app-info"
import AppBarMenu from "./components/app-bar-menu"
import BusinessLogo from "../../soe-theme/src/components/business-logo"
import LanguageSelector from "../../soe-theme/src/components/language-selector"
import SoeAccountCircleIcon from "../../soe-theme/src/icons/SoeAccountCircleIcon"
import SoeKeyboardArrowDownIcon from "../../soe-theme/src/icons/SoeKeyboardArrowDownIcon"
import UserContext from "../../services/user/context"

import { createAlert } from "../alert/slice"
import { currentAuthenticatedUser, signOut } from "../authenticator/slice"
import { isDev, isProd } from "../../utils/get-environment"
import { useGetUserProfileQuery } from "../../services/user/slice"
import { useGetVersionCheckQuery } from "../../services/version-check/slice"

// Custom style

import { CustomAppBar, CustomButton } from "./styled"
import { LANGUAGES } from "../../constant"
import AUTHENTICATION_STATE from "./constant"

function SoeAppBar({ setShowAuthenticator }) {
  const { i18n, t } = useTranslation("appBar")
  const customTheme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false)
  const [userLoggedIn, setUserLoggedIn] = useState(false)

  const { currentUser, setCurrentUser } = useContext(UserContext)

  const { formState } = useSelector((state) => state.authenticator)

  const { data: userProfile } = useGetUserProfileQuery(undefined, { skip: !userLoggedIn || formState !== "signedIn" })
  const { data: versionCheck } = useGetVersionCheckQuery(AppConfig.app.version, { skip: !userLoggedIn || formState !== "signedIn" })

  useEffect(() => {
    dispatch(currentAuthenticatedUser()).then((response) => {
      if (response.payload) {
        setUserLoggedIn(true)
      } else {
        setUserLoggedIn(false)
      }
    })
  }, [formState])

  useEffect(() => {
    if (userLoggedIn) {
      dispatch(setShowAuthenticator(false))
    }
  }, [userLoggedIn])

  useEffect(() => {
    if (userProfile) {
      if (userProfile.isAdmin === true) {
        setCurrentUser(userProfile)
      } else {
        setCurrentUser(undefined)
        dispatch(signOut()).then(() => {
          const resetStore = createAction("RESET_STORE")
          dispatch(resetStore())
        })
        navigate("/", { replace: true })
      }
    }
  }, [userProfile])

  useEffect(() => {
    if (versionCheck?.updateRequired) {
      dispatch(createAlert(t("newVersion")))
    }
  }, [versionCheck])

  const handleUserMenu = (event) => {
    setAnchorEl(event.currentTarget)
    setIsUserMenuOpen(true)
  }

  const handleAppBarMenuOnClose = () => {
    setIsUserMenuOpen(false)
  }

  const handleAuthenticator = (event) => {
    setAnchorEl(event.currentTarget)
    dispatch(setShowAuthenticator(true))
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CustomAppBar position="sticky" elevation={0}>
        <Toolbar variant="regular">
          <Link to="/">
            <BusinessLogo />
          </Link>
          {!isProd() && <AppInfo />}
          {isDev() && (
            <Box component="div" ml={1}>
              <LanguageSelector i18n={i18n} languages={LANGUAGES} />
            </Box>
          )}
          <Box component="div" sx={{ flexGrow: 1, justifyContent: "flex-end" }} />
          {formState === AUTHENTICATION_STATE.SIGNED_IN && currentUser?.emailAddress && (
            <>
              <Divider orientation="vertical" style={{ minHeight: customTheme.utils.pxToRem(24), margin: `0 ${customTheme.utils.pxToRem(16)} 0 ${customTheme.utils.pxToRem(24)}` }} />
              <CustomButton
                onClick={handleUserMenu}
                variant="outlined"
                size="medium"
                aria-controls={open ? "menu-appbar" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                color="inherit"
                startIcon={<SoeAccountCircleIcon color="contentActionDefault" />}
                endIcon={<SoeKeyboardArrowDownIcon color="contentNeutralPrimary" sx={(theme) => ({ ml: theme.utils.pxToRem(12), visibility: "visible" })} />}
                sx={(theme) => ({ padding: theme.utils.pxToRem(8), borderColor: theme.palette.backgroundNeutralTransparent.main, textTransform: "none" })}
              >
                <Typography variant="bodyMedium600" component="span" sx={(theme) => ({ color: theme.palette.contentNeutralPrimary.main })}>
                  {currentUser?.emailAddress}
                </Typography>
              </CustomButton>
            </>
          )}
          {formState === AUTHENTICATION_STATE.SIGN_IN && !currentUser && (
            <>
              <Divider orientation="vertical" style={{ minHeight: customTheme.utils.pxToRem(24), margin: `0 ${customTheme.utils.pxToRem(16)} 0 ${customTheme.utils.pxToRem(24)}` }} />
              <CustomButton
                onClick={handleAuthenticator}
                variant="outlined"
                size="medium"
                aria-controls={open ? "menu-appbar" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                color="inherit"
                startIcon={<SoeAccountCircleIcon color="contentActionDefault" />}
                endIcon={<SoeKeyboardArrowDownIcon color="contentNeutralPrimary" sx={(theme) => ({ ml: theme.utils.pxToRem(12), visibility: "hidden" })} />}
                sx={(theme) => ({ padding: theme.utils.pxToRem(8), borderColor: theme.palette.backgroundNeutralTransparent.main, textTransform: "none" })}
              >
                <Typography variant="bodyMedium600" component="span" sx={(theme) => ({ color: theme.palette.contentNeutralPrimary.main })}>
                  {t("connection")}
                </Typography>
              </CustomButton>
            </>
          )}
          <AppBarMenu open={isUserMenuOpen} onClose={handleAppBarMenuOnClose} anchorEl={anchorEl} />
        </Toolbar>
      </CustomAppBar>
    </Box>
  )
}

SoeAppBar.propTypes = {
  setShowAuthenticator: PropTypes.func.isRequired,
}

export default SoeAppBar
