import restApiSlice from "../../services/rest-api/slice"

export const invoicesRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPaidInvoices: builder.query({
      query: () => ({ url: "/invoices/paid", method: "GET" }),
      providesTags: ["PaidInvoices"],
      transformResponse: (response) =>
        response.data.map((invoice) => {
          return {
            ...invoice,
            summary: {
              ...invoice.summary,
              amountPaid: invoice.summary?.amountPaid !== undefined ? invoice.summary.amountPaid : 0,
              amountOutstanding: invoice.summary?.amountOutstanding !== undefined ? invoice.summary.amountOutstanding : invoice.summary.invoice.total,
            },
          }
        }),
    }),
    getUnpaidInvoices: builder.query({
      query: () => ({ url: "/invoices/pending", method: "GET" }),
      providesTags: ["UnpaidInvoices"],
      transformResponse: (response) =>
        response.data.map((invoice) => {
          return {
            ...invoice,
            summary: {
              ...invoice.summary,
              amountPaid: invoice.summary.amountPaid >= 0 ? invoice.summary.amountPaid : 0,
              amountOutstanding: invoice.summary.amountOutstanding >= 0 ? invoice.summary.amountOutstanding : invoice.summary.invoice.total,
            },
          }
        }),
    }),
    getInvoice: builder.query({
      query: (invoiceId) => ({ url: `/invoices/${invoiceId}`, method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    getInvoicesCandidatesUsers: builder.query({
      query: () => ({ url: "/invoices/candidates/users", method: "GET" }),
      providesTags: ["InvoicesCandidates"],
      transformResponse: (response) => response.data,
    }),
    getInvoicesCandidatesUser: builder.query({
      query: ({ userId, currency }) => ({ url: `/invoices/candidates/users/${userId}/${currency}`, method: "GET" }),
      providesTags: ["InvoicesCandidates"],
      transformResponse: (response) => response.data,
    }),
    createUsersInvoice: builder.mutation({
      query: () => ({ url: "/invoices/users", method: "POST", body: undefined }),
      invalidatesTags: ["InvoicesCandidates", "UnpaidInvoices"],
      transformResponse: (response) => response.data,
    }),
    createUserInvoice: builder.mutation({
      query: ({ userId, currency }) => ({ url: `/invoices/users/${userId}/${currency}`, method: "POST", body: undefined }),
      invalidatesTags: ["InvoicesCandidates", "UnpaidInvoices"],
      transformResponse: (response) => response.data,
    }),
    createCredits: builder.mutation({
      query: (payload) => ({ url: "/credits", method: "POST", body: payload }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["InvoicesCandidates"],
    }),
    createPaymentRecord: builder.mutation({
      query: ({ id, payload }) => ({ url: `/payments/manual/invoice/${id}`, method: "POST", body: payload }),
      invalidatesTags: ["UnpaidInvoices", "PaidInvoices"],
      transformResponse: (response) => response.data,
    }),
    sendEmailInvoice: builder.mutation({
      query: ({ payload }) => ({ url: `/invoices/email`, method: "POST", body: payload }),
      invalidatesTags: ["UnpaidInvoices"],
      transformResponse: (response) => response.data,
    }),
    chargePaymentMethod: builder.mutation({
      query: ({ id, payload }) => ({ url: `/payments/charge/invoice/${id}`, method: "POST", body: payload }),
      transformResponse: (response) => response.data,
    }),
  }),
})

export const {
  useCreateCreditsMutation,
  useCreatePaymentRecordMutation,
  useCreateUserInvoiceMutation,
  useCreateUsersInvoiceMutation,
  useGetInvoiceQuery,
  useGetInvoicesCandidatesUsersQuery,
  useGetInvoicesCandidatesUserQuery,
  useGetPaidInvoicesQuery,
  useGetUnpaidInvoicesQuery,
  useSendEmailInvoiceMutation,
  useChargePaymentMethodMutation,
} = invoicesRestApiSlice
