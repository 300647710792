import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"

// MUI
import Box from "@mui/material/Box"
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import CustomDatePicker from "../../../date-picker"
import { useGetClientsQuery, useGetCurrenciesQuery } from "../../../clients/slice"
import { useCreateCreditsMutation } from "../../slice"
import ClientsAutocomplete from "../../../clients-autocomplete"

function AddCreditDrawer({ onSubmitEvent, setShowCreditDrawer }) {
  const { t } = useTranslation("invoices")
  const { data: clients } = useGetClientsQuery()
  const { data: currencies } = useGetCurrenciesQuery()
  const [createCredits] = useCreateCreditsMutation()

  const formik = useFormik({
    initialValues: {
      creditDate: useMemo(() => new Date(), []),
      client: "",
      orderNumber: "",
      title: "",
      description: "",
      amount: "",
      currency: "CAD",
      gst: "",
      pst: "",
      total: "",
      privateNote: "",
    },
    validate: (formvalues) => {
      const errors = {}
      if (!formvalues.creditDate) {
        errors.creditDate = true
      }
      if (!formvalues.client) {
        errors.client = true
      }
      if (!formvalues.title) {
        errors.title = true
      }
      if (!formvalues.amount) {
        errors.amount = true
      }
      if (!formvalues.currency) {
        errors.currency = true
      }
      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formvalues) => {
      let temporaryCredit = {
        creditDate: new Date(formvalues.creditDate).toISOString(),
        client: formvalues.client.emailAddress,
        title: formvalues.title,
        description: formvalues.description || undefined,
        creditAmount: {
          amount: formvalues.amount,
          currency: formvalues.currency,
        },
        privateNote: formvalues.privateNote || undefined,
        orderNumber: formvalues.orderNumber || undefined,
      }

      if (formvalues.gst)
        temporaryCredit = {
          ...temporaryCredit,
          taxGst: {
            amount: formvalues.gst,
            currency: formvalues.currency,
          },
        }

      if (formvalues.pst)
        temporaryCredit = {
          ...temporaryCredit,
          taxPst: {
            amount: formvalues.pst,
            currency: formvalues.currency,
          },
        }

      await createCredits(temporaryCredit)
      setShowCreditDrawer(false)
    },
  })

  useEffect(() => {
    formik.handleReset()
  }, [])

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <CustomDatePicker
            label={t("addCreditDrawer.form.fields.creditDate.label")}
            name="creditDate"
            value={formik.values.creditDate}
            disablePast
            error={formik.touched.creditDate && formik.errors.creditDate}
            onChange={(value) => formik.setFieldValue("creditDate", value)}
          />
        </Box>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <ClientsAutocomplete formik={formik} clients={clients} />
        </Box>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <TextField id="orderNumber" variant="outlined" size="small" value={formik.values.orderNumber || ""} onChange={formik.handleChange} onBlur={formik.handleBlur} label={t("addCreditDrawer.form.fields.orderNumber.label")} />
        </Box>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <TextField
            id="title"
            variant="outlined"
            label={t("addCreditDrawer.form.fields.title.label")}
            size="small"
            value={formik.values.title || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.title && formik.errors.title !== undefined}
          />
        </Box>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <TextField id="description" variant="outlined" label={t("addCreditDrawer.form.fields.description.label")} size="small" value={formik.values.description || ""} onChange={formik.handleChange} onBlur={formik.handleBlur} />
        </Box>
        <Box component="div" sx={(theme) => ({ display: "flex", marginBottom: theme.spacing(1) })}>
          <TextField
            sx={(theme) => ({ marginRight: theme.spacing(1) })}
            id="amount"
            variant="outlined"
            label={t("addCreditDrawer.form.fields.amount.label")}
            size="small"
            value={formik.values.amount || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.amount && formik.errors.amount !== undefined}
            inputMode="numeric"
            type="number"
            inputProps={{ min: 0 }}
          />
          <FormControl fullWidth error={formik.touched?.declaredValue?.currency && formik.errors?.currency}>
            <InputLabel />
            {currencies && (
              <Select name="currency" onChange={formik.handleChange} value={formik.values?.currency || "CAD"} size="small" error={formik.touched.currency && formik.errors.currency !== undefined}>
                {currencies &&
                  currencies.map((currency, index) => (
                    <MenuItem key={currency.code.concat(index)} value={currency.code}>
                      {currency.code}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </FormControl>
        </Box>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <TextField
            id="gst"
            inputMode="numeric"
            type="number"
            inputProps={{ min: 0 }}
            variant="outlined"
            label={t("addCreditDrawer.form.fields.gst.label")}
            size="small"
            value={formik.values.gst || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Box>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <TextField
            id="pst"
            inputMode="numeric"
            type="number"
            inputProps={{ min: 0 }}
            variant="outlined"
            label={t("addCreditDrawer.form.fields.pst.label")}
            size="small"
            value={formik.values.pst || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Box>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <TextField
            id="total"
            inputMode="numeric"
            type="number"
            inputProps={{ min: 0 }}
            variant="outlined"
            label={t("addCreditDrawer.form.fields.total.label")}
            size="small"
            value={(Number(formik.values.gst) + Number(formik.values.pst) + Number(formik.values.amount)).toFixed(2) || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          />
        </Box>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <TextField id="privateNote" variant="outlined" label={t("addCreditDrawer.form.fields.privateNote.label")} size="small" value={formik.values.privateNote || ""} onChange={formik.handleChange} onBlur={formik.handleBlur} />
        </Box>
      </form>
    </Box>
  )
}

AddCreditDrawer.defaultProps = {
  onSubmitEvent: undefined,
}

AddCreditDrawer.propTypes = {
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowCreditDrawer: PropTypes.func.isRequired,
}

export default AddCreditDrawer
