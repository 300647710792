import Card from "@mui/material/Card"
import React from "react"
import Skeleton from "@mui/material/Skeleton"

import UserGroup from "./components/user-group"

import { useGetInvoicesCandidatesUsersQuery } from "../../slice"

function ReadyToInvoice() {
  const { data: userCandidates, isFetching } = useGetInvoicesCandidatesUsersQuery()

  return (
    <Card variant="elevation" square>
      {!userCandidates || isFetching ? (
        <Skeleton variant="rectangular" width="100%" height={100} />
      ) : (
        <>
          {userCandidates.map((userCandidate, index) => {
            return <UserGroup key={"".concat(index)} user={userCandidate.user} candidates={userCandidate.candidates} currency={userCandidate.currency} />
          })}
        </>
      )}
    </Card>
  )
}

export default ReadyToInvoice
