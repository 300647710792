// REACT
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardHeader from "@mui/material/CardHeader"
import Divider from "@mui/material/Divider"
import Link from "@mui/material/Link"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Formik
import { useFormik } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

// Custom
import CardContentNoPaddingBottom from "../../../../soe-theme/src/components/cardContent-no-padding-bottom"
import CardContentNoPaddingTop from "../../../../soe-theme/src/components/cardContent-no-padding-top"
import LanguageSelector from "../../../../soe-theme/src/components/language-selector"
import { forgotPasswordSubmit, forgotPassword, showSignIn } from "../../slice"
import { LANGUAGES } from "../../../../constant"

function ForgotPasswordSubmit() {
  const customTheme = useTheme()
  const { i18n, t } = useTranslation("authenticator")
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      email: useSelector((state) => state.authenticator.email),
      verificationCode: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validate: (formValues) => {
      const errors = {}
      if (!formValues.verificationCode) {
        errors.verificationCode = t("forgotPasswordSubmit.fields.verificationCode.error.required")
      }
      if (!formValues.newPassword) {
        errors.newPassword = t("forgotPasswordSubmit.fields.newPassword.error.required")
      }
      if (!formValues.confirmNewPassword) {
        errors.confirmNewPassword = t("forgotPasswordSubmit.fields.confirmNewPassword.error.required")
      }
      if (formValues.newPassword !== formValues.confirmNewPassword) {
        errors.confirmNewPassword = t("forgotPasswordSubmit.fields.confirmNewPassword.error.passwordMatch")
      }
      return errors
    },
    validateOnChange: false,
    onSubmit: async (formValues) => {
      dispatch(forgotPasswordSubmit(formValues))
    },
  })

  return (
    <Box className="forgotPasswordSubmit" component="div" my="auto" sx={{ display: "flex", justifyContent: "center" }}>
      <Card mt={2} mb={2}>
        <CardHeader title={t("forgotPasswordSubmit.title")} />
        <Divider />
        <Box component="div" mb={2} mt={1} mx={2} sx={(theme) => ({ width: theme.utils.pxToRem(452) })}>
          <CardContentNoPaddingBottom sx={{ justifyContent: "center" }}>
            <Box component="div" pb={2}>
              <CardContentNoPaddingTop sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Typography sx={(theme) => ({ fontSize: "1.063rem", fontWeight: theme.typography.fontWeightBold })}>{t("forgotPasswordSubmit.emailSent1")}</Typography>
                <Typography sx={{ fontSize: "1.063rem" }}>{t("forgotPasswordSubmit.emailSent2")}</Typography>
                <Typography sx={(theme) => ({ fontSize: theme.typography.bodyMedium600.fontSize, textAlignLast: "center" })}> {formik.values.email}</Typography>
              </CardContentNoPaddingTop>
            </Box>
            <form onSubmit={formik.handleSubmit} noValidate>
              <TextField
                id="verificationCode"
                inputProps={{ style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular, lineHeight: customTheme.utils.pxToLineHeight(16, 24) } }}
                label={t("forgotPasswordSubmit.fields.verificationCode.label")}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.verificationCode}
                error={formik.touched.verificationCode && formik.errors.verificationCode !== undefined}
                helperText={(formik.touched.verificationCode && formik.errors.verificationCode) || " "}
                required
                autoFocus
              />
              <Box component="div" p={2} />
              <Divider sx={{ position: "initial" }}>{t("forgotPasswordSubmit.separator")}</Divider>
              <Box component="div" p={2} />
              <TextField
                id="newPassword"
                inputProps={{ style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular, lineHeight: customTheme.utils.pxToLineHeight(16, 24) } }}
                label={t("forgotPasswordSubmit.fields.newPassword.label")}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
                error={formik.touched.newPassword && formik.errors.newPassword !== undefined}
                helperText={(formik.touched.newPassword && formik.errors.newPassword) || " "}
                fullWidth
                required
                type="password"
              />
              <Box component="div" p={1} />
              <TextField
                id="confirmNewPassword"
                inputProps={{ style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular, lineHeight: customTheme.utils.pxToLineHeight(16, 24) } }}
                label={t("forgotPasswordSubmit.fields.confirmNewPassword.label")}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmNewPassword}
                error={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword !== undefined}
                helperText={(formik.touched.confirmNewPassword && formik.errors.confirmNewPassword) || " "}
                fullWidth
                required
                type="password"
              />
              <Box component="div">
                <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                  <Button type="submit" sx={{ display: "flex", textTransform: "none", borderRadius: "2px" }}>
                    {t("forgotPasswordSubmit.fields.forgotPasswordSubmitButton.label")}
                  </Button>
                </CardContent>
              </Box>
              <Box component="div">
                <CardContentNoPaddingBottom sx={{ display: "flex", justifyContent: "center" }}>
                  <Link onClick={() => dispatch(forgotPassword(formik.values))} sx={(theme) => ({ textDecoration: "none", fontSize: theme.typography.bodyMedium600.fontSize })}>
                    {t("forgotPasswordSubmit.lostCode")}
                  </Link>
                </CardContentNoPaddingBottom>
              </Box>
              <Box component="div">
                <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                  <Link onClick={() => dispatch(showSignIn())} sx={(theme) => ({ textDecoration: "none", fontSize: theme.typography.bodyMedium600.fontSize })}>
                    {t("forgotPasswordSubmit.backtoSignIn")}
                  </Link>
                </CardContent>
              </Box>
              <Box component="div">
                <CardContentNoPaddingBottom sx={{ display: "flex", justifyContent: "center" }}>
                  <LanguageSelector i18n={i18n} languages={LANGUAGES} />
                </CardContentNoPaddingBottom>
              </Box>
            </form>
          </CardContentNoPaddingBottom>
        </Box>
      </Card>
    </Box>
  )
}

export default ForgotPasswordSubmit
