import Box from "@mui/material/Box"
import CardContent from "@mui/material/CardContent"
import Card from "@mui/material/Card"
import { styled } from "@mui/material/styles"

export const MuiWrapper = styled(Box, { name: "RateRequestInformationCard", slot: "Root" })(() => ({}))

const getCursorType = (disabled) => {
  if (disabled) {
    return "not-allowed"
  }
  return "default"
}

export const CustomCard = styled(Card, { name: "CustomCard", slot: "Root" })(({ theme, disabled }) => ({
  color: disabled && theme.palette.contentNeutralTertiary.main,
  backgroundColor: disabled && theme.palette.backgroundNeutralTransparent.main,
  cursor: getCursorType(disabled),
}))

export const CustomCardContent = styled(CardContent, { name: "CustomCardContent", slot: "Root" })(({ theme }) => ({
  padding: theme.spacing(2),
  "&:last-child": {
    padding: theme.spacing(2),
  },
}))
